export async function trycatchAsync <ReturnType = any> (func: () => any, fail: any, { logError = false } = {}): Promise<ReturnType> {
  try {
    return await func()
  } catch (e) {
    if (logError) console.error(e)
    return fail
  }
}

export function trycatchSync (func: () => any, fail: any, { logError = false } = {}) {
  try {
    return func()
  } catch (e) {
    if (logError) console.error(e)
    return fail
  }
}

export function trycatch (func: () => any, fail: any) {
  try {
    return func()
  } catch (e) {
    return fail
  }
}

/**
 * Wraps a function with a try-catch, returning a result object with either
 * an error or data key.
 *
 * @example
 * const safeFetch = withTryCatch(fetch)
 * const result = await safeFetch('https://example.com')
 * if (result.ok) {
 *   console.log(result.data)
 * } else {
 *   console.error(result.error)
 * }
 *
 * @param {Function} fn - The function to wrap with a try-catch.
 * @returns {Function} A new function that calls the original and returns a result object.
 */
export function withTryCatch (fn: (...args: any[]) => any) {
  return (...args: any[]) => {
    try {
      const result = fn(...args)
      return { ok: true, data: result }
    } catch (error) {
      return { ok: false, error }
    }
  }
}

/**
 * Wraps an asynchronous function with a try-catch, returning a promise that resolves
 * to a result object containing either an error or data key.
 *
 * @example
 * const safeFetch = withTryCatchAsync(fetch)
 * const result = await safeFetch('https://example.com')
 * if (result.ok) {
 *   console.log(result.data)
 * } else {
 *   console.error(result.error)
 * }
 *
 * @param {Function} fn - The asynchronous function to wrap with a try-catch.
 * @returns {Function} A new function that calls the original and resolves to a result object.
 */

export function withTryCatchAsync (fn: (...args: any[]) => Promise<any>) {
  return async (...args: any[]) => {
    try {
      const result = await fn(...args)
      return { ok: true, data: result }
    } catch (error) {
      return { ok: false, error }
    }
  }
}
