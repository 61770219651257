
// import { InventoryState } from '../../state'
import { NoteCreditState } from './state'

export default {
  SET_ALL (state: NoteCreditState, entities: any[]): void {
    state.pagos = entities
  },
  PUSH (state: NoteCreditState, entitie: any): void {
    state.pagos.unshift(entitie)
  },
  UPDATE (state: NoteCreditState, entitie: any): void {
    const index = state.pagos.findIndex(p => p.id === entitie.id)
    if (index !== -1) {
      state.pagos.splice(index, 1, entitie)
    }
  }
}
