
import { ActionContext } from 'vuex'
import { State } from './state'
import objToFormData from '@/utils/objToFormData'

const URL_ACTION = '/apis/quotation/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, payload: { search: '', note_sale: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  },
  async sendEmail ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}sendemail/${payload.id}`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async getMembrete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}membrete/${payload.id}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async getMembretes ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}membrete`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async createMembrete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}membrete`,
        method: 'POST',
        data: formData
      },
      { root: true }
    )
    return data
  },
  async updateMembrete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}membrete/${payload.id}`,
        method: 'PUT',
        data: formData
      },
      { root: true }
    )
    return data
  },
  async deleteMembrete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}membrete/${payload.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    return data
  },
  async activMembrete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}active_membrete`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async changeStatus ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}status/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  },
  async getHistoryStatus ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/${payload.entity_type}/${payload.entity_id}/status/history/`,
        method: 'GET'
        // data: payload
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  },
  async createComplement ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}complement/${payload}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}
export default actions
