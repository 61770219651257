<template>
  <WrapContainer class="relative-container">
    <slot name="content"></slot>
    <v-fade-transition>
      <div v-if="loading" class="overlay">
        <span class="mb-2 font-weight-bold red--text text-h6">Cargando {{ text }}...</span>
        <div class="loader"></div>
      </div>
    </v-fade-transition>
  </WrapContainer>
</template>

<script>
export default {
  components: {
    WrapContainer: () => import('@/components/utils/WrapContainer.vue')
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.relative-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #FF3D00;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
