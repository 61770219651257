const mantenimientoRoutes = {
  meta: {
    title: 'Mantenimiemto'
  },
  path: 'mantenimiento',
  component: () => import('@/pages/Units/Mantenimiento/index.vue'),
  name: 'mantenimiento',
  children: [
    {
      meta: {
        title: 'Mantenimiemto'
      },
      path: '',
      name: 'mantenimiento',
      component: () => import('@/pages/Units/Mantenimiento/Mantenimiento.vue')
    },
    {
      meta: {
        title: 'Registrar mantenimiento'
      },
      path: 'nuevo-mantenimiento',
      name: 'nuevo-mantenimiento',
      component: () => import('@/pages/Units/Mantenimiento/CreateMantenimiento.vue')
      // component: () => import('@/components/Inventory/panel_menus/Qr_info.vue')
    },
    {
      meta: {
        title: 'Control de kilometraje'
      },
      path: 'control-kilometraje',
      name: 'control-kilometraje',
      component: () => import('@/pages/Units/Mantenimiento/ControlKilometrajeList.vue')
      // component: () => import('@/components/Inventory/panel_menus/Qr_info.vue')
    },
    {
      meta: {
        title: 'Informe por unidad'
      },
      path: 'informe-unidad',
      name: 'informe-unidad',
      component: () => import('@/pages/Units/Mantenimiento/ReportMantto.vue')
    }
  ]
}

export default mantenimientoRoutes
