import DeviceItem from "../item/Device.item";
import IDevice, {
  AddDeviceReqDTO,
  CaptureRealtimeReqDTO,
  CaptureRealtimeResDTO,
  DeleteDeviceReqDTO,
  DeviceUpdateReqDTO,
  GetVideoClipReqDTO,
  QueryDeviceReqDTO,
  VideoDownloadByIdsReqDTO,
  VideoDownloadByIdsResDTO,
} from "../interfaces/Device.interface";
import StreamaxJSSdk from "..";
import ResourceApi from "./ResourceApi.base";
import {
  RequestCommonRespose,
  RequestData,
} from "@/interfaces/RequestsWrapper.interface";
import { DateTime } from "luxon";
import axios, { AxiosRequestConfig } from "axios";

export default class DeviceApi extends ResourceApi<
  IDevice,
  AddDeviceReqDTO,
  DeviceUpdateReqDTO,
  QueryDeviceReqDTO,
  DeleteDeviceReqDTO
> {
  apiPlaceUrl: string;
  itemClass: typeof DeviceItem;

  constructor(sdk: StreamaxJSSdk) {
    super(sdk);
    this.apiPlaceUrl = "/devices";
    this.itemClass = DeviceItem;
  }

  /**
   * This API is used to add a device through the device serial number
   */
  public async create(params: AddDeviceReqDTO, { axiosConfig = {} } = {}) {
    const data = await this.createUtil(params, axiosConfig);
    return data;
  }

  /**
   * This API is used to modify the fleetId or lanWan of existing device according to uniqueId.
   * At least one of fleetId and lanWan has to be provided.
   */
  public async update(id: string, params: DeviceUpdateReqDTO, { axiosConfig = {} } = {}) {
    const data = await this.updateUtil(id, params, axiosConfig);
    return data;
  }

  /**
   * This API is used to delete devices based on the device serial number.
   *  Batch deletion is supported, and a maximum of 50 devices can be deleted in a batch.
   */
  public async delete(params: DeleteDeviceReqDTO, { axiosConfig = {} } = {}) {
    const data = await this.deleteUtil(params, axiosConfig);
    return data;
  }

  /**
   * This API is used to obtain the device list information.
   * The device information also contains the real-time online status.
   * The list is displayed by default in ascending order of the device serial number.
   */
  public async list(params?: QueryDeviceReqDTO, { axiosConfig = {} } = {}) {
    const data = await this.listUtil(params as any, axiosConfig);
    return data;
  }

  public async captureRealtime(
    params: CaptureRealtimeReqDTO
  ): Promise<RequestCommonRespose<CaptureRealtimeResDTO[]>> {
    const { data } = await axios.post<RequestCommonRespose<CaptureRealtimeResDTO[]>>(`${StreamaxJSSdk.config.baseURL}/openapi/capture/realTime`, params, {
      headers: {
        "Content-Type": "application/json",
        _sign: StreamaxJSSdk.config._sign,
        _tenantid: StreamaxJSSdk.config._tenantid,
      }
    });
    return data;
  }

  public async videoDownloadByIds(
    ids: VideoDownloadByIdsReqDTO['videoIds']
  ): Promise<VideoDownloadByIdsResDTO> {
    const { data } = await axios.get<VideoDownloadByIdsResDTO>(
      `${StreamaxJSSdk.config.baseURL}/openapi/v2/devices/video-download?videoIds=${ids}`, {
      headers: {
        _sign: StreamaxJSSdk.config._sign,
        _tenantid: StreamaxJSSdk.config._tenantid,
      }
    });
    return data;
  }

  public async getVideoClip(
    uniqueId: string,
    {
      blackBoxType = "ALL",
      startTime = DateTime.utc().minus({ seconds: 5 }).toISO(),
      endTime = DateTime.utc().toISO(),
      channels = "1",
      streamType = "MAIN_STREAM" as "MAIN_STREAM" | "SUB_STREAM",
    } = {}
  ): Promise<RequestData<{ videoId: string }>> {
    const { data } = await StreamaxJSSdk.axios<
      RequestData<{ videoId: string }>
    >({
      url: `${this.apiPlaceUrl}/${uniqueId}/video-download`,
      method: "POST",
      data: {
        blackBoxFileParam: {
          blackBoxType,
        },
        startTime,
        endTime,
        videoFileParam: {
          channels,
          streamType,
        },
      },
    });
    return data;
  }
}
