var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('v-dialog', {
    staticClass: "system-updates-modal",
    attrs: {
      "max-width": "700px",
      "persistent": ""
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "dense": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "font-weight-medium"
  }, [_vm._v("Novedades del Sistema")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pt-4 pb-6"
  }, [_vm.loading ? _c('div', {
    staticClass: "text-center pa-4"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _c('div', {
    staticClass: "mt-2"
  }, [_vm._v("Cargando actualización...")])], 1) : !_vm.update ? _c('div', {
    staticClass: "text-center pa-4"
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1",
      "large": ""
    }
  }, [_vm._v("mdi-information-outline")]), _c('div', {
    staticClass: "caption grey--text mt-1"
  }, [_vm._v("No se encontró la actualización")])], 1) : _c('div', [_c('div', {
    staticClass: "d-flex mb-3 align-center"
  }, [_c('v-avatar', {
    staticClass: "mr-3 elevation-1",
    attrs: {
      "color": _vm.getUpdateTypeColor(_vm.update.update_type),
      "size": "48"
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(_vm._s(_vm.getUpdateTypeIcon(_vm.update.update_type)))])], 1), _c('div', [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": _vm.getImportanceColor(_vm.update.importance),
      "label": "",
      "text-color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.getImportanceLabel(_vm.update.importance)) + " ")]), _c('h3', {
    staticClass: "text-h6 font-weight-bold mb-0"
  }, [_vm._v(_vm._s(_vm.update.title))])], 1), _c('div', {
    staticClass: "d-flex align-center mt-1"
  }, [_c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "x-small": "",
      "outlined": "",
      "label": ""
    }
  }, [_vm._v("v" + _vm._s(_vm.update.version))]), _c('span', {
    staticClass: "caption grey--text"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.update.release_date)))])], 1)])], 1), _c('v-divider'), _c('div', {
    staticClass: "mt-4 update-description",
    domProps: {
      "innerHTML": _vm._s(_vm.formatDescription(_vm.update.description))
    }
  })], 1)])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }