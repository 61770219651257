var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "publising_error_notification",
    attrs: {
      "outlined": "",
      "color": _vm.color
    }
  }, [_c('v-card-title', {
    staticClass: "publising_error_notification_title"
  }, [_c('v-avatar', {
    attrs: {
      "size": "40px"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.strategy.thumbnail,
      "contain": ""
    }
  })]), _c('span', [_vm._v(" ")]), _c('span', {
    staticClass: "text-h6 font-weight-light"
  }, [_vm._v(_vm._s(_vm.strategy.label))])], 1), _vm.socialMediaError ? [_c('v-card-title', {
    staticClass: "publising_error_notification_title"
  }, [_vm._v(_vm._s(_vm.socialMediaError.name) + " error")]), _c('v-card-text', [_vm._v(_vm._s(_vm.socialMediaError.description))])] : _vm._e(), _vm.additionalErrorText ? _c('v-card-title', {
    staticClass: "publising_error_notification_title"
  }, [_vm._v(_vm._s(_vm.additionalErrorText))]) : _vm._e(), _vm.hasApiError(_vm.responseApiError) ? [_c('v-card-title', {
    staticStyle: {
      "padding-top": "2px",
      "padding-bottom": "5px"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.showAdditionalInfo = !_vm.showAdditionalInfo;
      }
    }
  }, [_vm._v("Mostrar detalles adicionales")]), _c('v-divider', {
    staticStyle: {
      "margin-left": "7px",
      "margin-right": "7px"
    },
    attrs: {
      "vertical": ""
    }
  }), _vm.showAdditionalInfo ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "x-small": "",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.copyErrors.apply(null, arguments);
      }
    }
  }, [_vm._v("Copiar contenido")]) : _vm._e()], 1), _vm.showAdditionalInfo ? _c('v-card-text', [_c('div', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.responseApiError.name))]), _c('div', {
    staticClass: "subtitle-2"
  }, [_vm._v(_vm._s(_vm.responseApiError.description))]), _c('div', {
    staticClass: "publising_error_trace"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.responseApiError.message))]), _vm.socialMediaError ? _c('pre', {
    staticClass: "error_trace"
  }, [_c('code', [_vm._v(_vm._s(_vm.formatJSONTrace(_vm.socialMediaError)))])]) : _vm._e(), _c('pre', {
    staticClass: "error_trace"
  }, [_c('code', [_vm._v(_vm._s(_vm.formatJSONTrace(_vm.responseApiError.trace)))])])])]) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }