import { ActionContext } from 'vuex'
import { State } from './state'
import ChecklistTask from '@/classes/Checklist/Tasks'

const URL_ACTION = '/apis/tasks/'

const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, payload = { search: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}get/all`,
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async loadAssigned ({ dispatch }: ActionContext<State, string>, payload = { search: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}assigned`,
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    return data
    // commit('SET_ALL', data)
  },
  async loadTemplates ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get/templates`,
          method: 'GET'
        },
        { root: true }
      )
      commit('SET_TEMPLATE', data)
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al obtener las tareas asignadas de la lista de verificación', color: 'error', message: '', error }, { root: true })
    }
  },
  async create ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: { param: string, form: any }) {
    try {
      const response = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}create`,
          method: 'POST',
          data: payload.form,
          params: { param: payload.param }
        },
        { root: true }

      )
      await dispatch('app/notifyToast', { msj: 'Tarea Creada con Exito', type: 'success' }, { root: true })
      return response
    } catch (error) {
      await dispatch('app/notifyToast', { msj: 'No se ha podido guardar el checklist', type: 'error' }, { root: true })
      throw error
    }
  },
  async update ({ dispatch, rootState, commit }: ActionContext<State, string>, payload: ChecklistTask) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}update/${payload.id}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )
      commit('UPDATE', data)
      await dispatch('loadAssigned')
      await dispatch('loadTemplates')
      await dispatch('app/notification', { content: 'Tarea Actualizada', type: 'success' }, { root: true })
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al actualizar la tarea', color: 'error', message: '', error }, { root: true })
    }
  },
  async delete ({ dispatch, rootState, commit }: ActionContext<State, string>, taskId: number) {
    try {
      await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}delete/${taskId}`,
          method: 'DELETE'
        },
        { root: true }
      )
      commit('DELETE', taskId)
      await dispatch('app/notification', { content: 'Tarea Eliminada', type: 'success' }, { root: true })
    } catch (error) {
      dispatch('app/addLogWithError', { title: 'Error al eliminar la tarea', color: 'error', message: '', error }, { root: true })
    }
  },
  async loadDashboard ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}get/dashboard`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadDashboardUser ({ dispatch, rootState, commit }: ActionContext<State, string>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}get/dashboard/user`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async deleteMultiple ({ dispatch }: ActionContext<State, string>, payload: any) {
    await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}delete/multiple`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
  },
  async loadMetrics ({ dispatch }: ActionContext<State, string>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}metrics`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  }
}

export default actions
