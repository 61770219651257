import { SOCIAL_MEDIA_TYPE } from '@/store/catalogs/SOCIAL_MEDIA_TYPE'
import BaseSocialMediaStrategy, { ISocialMediaApiError } from './BaseSocialMediaStrategy'
import { FACEBOOK_ERROR_TYPE, FACEBOOK_ERRORS, FACEBOOK_PROGRAMING_ERROR_CODES } from '@/store/catalogs/FACEBOOK_GRAPHAPI_ERRORS'
import { ApiErrorHandler } from '@/interfaces/ApiErrorHandler.interface'
import { FacebookSchedulePosts, IFacebookApiError } from '@/interfaces/Facebook.interface'
import FacebookSchedulePublication from '../SocialPublishScheduled/FacebookSchedulePublication'
import { IAccountSocialMedia } from '@/store/interfaces/SocialAccounts.interface'

export class FacebookSocialStrategy extends BaseSocialMediaStrategy {
  type: SOCIAL_MEDIA_TYPE;
  limitKBPostImageSize: number;
  limitKBPostVideoSize: number;
  allowedMimeTypes: Set<string>;
  mainColor: string

  constructor () {
    super()
    this.type = SOCIAL_MEDIA_TYPE.FACEBOOK
    this.limitKBPostImageSize = 1024 * 30
    this.limitKBPostVideoSize = 1024 * 100
    this.allowedMimeTypes = new Set([
      // Tipos MIME para Imágenes
      'image/jpeg', // JPEG (.jpg, .jpeg)
      'image/png', // PNG (.png)
      'image/gif', // GIF (.gif) - No animado
      'image/bmp', // BMP (.bmp)
      'image/tiff', // TIFF (.tiff, .tif)

      // Tipos MIME para Videos
      'video/mp4', // MP4 (.mp4)
      'video/quicktime', // MOV (.mov)
      'video/x-msvideo', // AVI (.avi)
      'video/x-ms-wmv', // WMV (.wmv)
      'video/x-flv', // FLV (.flv)
      'video/x-matroska', // MKV (.mkv)
      'video/webm' // WEBM (.webm)
    ])
    this.mainColor = '#1977F375'
  }

  async getErrors (files: File[]) {
    const haveInvalidFilesType = {
      rule: this.getInvalidFiles(files).length > 0,
      message: 'Facebook solo permite publicar imagenes o videos.'
    }
    const haveMoreThanAvaliableFiles = {
      rule: files.length > 10,
      message: `Facebook solo permite publicar hasta 10 archivos en un solo post. Archivos seleccionados: ${files.length} archivos.`
    }
    const haveMoreThanAvaliableVideo = {
      rule: files.filter((file) => file.type.startsWith('video')).length > 1,
      message: 'Facebook solo permite publicar 1 video por post.'
    }
    const haveImageAndVideoFiles = {
      rule:
        files.some((file) => file.type.startsWith('image')) &&
        files.some((file) => file.type.startsWith('video')),
      message:
        'Facebook no permite combinar imagenes y videos en el mismo post.'
    }

    return [
      haveMoreThanAvaliableFiles,
      haveMoreThanAvaliableVideo,
      haveImageAndVideoFiles,
      haveInvalidFilesType
    ]
  }

  getApiError (params: ApiErrorHandler<any, IFacebookApiError>): ISocialMediaApiError | undefined {
    switch (params.error) {
      case FACEBOOK_ERROR_TYPE.FACEBOOK_API_ERROR: {
        if (!params.trace?.error?.code) return
        const error = FACEBOOK_ERRORS[params.trace?.error?.code as keyof typeof FACEBOOK_ERRORS]
        return error
      }
      case FACEBOOK_ERROR_TYPE.FACEBOOK_PROGRAMING_ERROR: {
        if (!params.suberror) return
        const error = FACEBOOK_PROGRAMING_ERROR_CODES[params.suberror as keyof typeof FACEBOOK_PROGRAMING_ERROR_CODES]
        return error
      }
    }
  }

  showAssetsTextArea (): boolean {
    return true
  }

  public serializeSchedulePublicationData (
    data: ApiErrorHandler<FacebookSchedulePosts>, strategy: BaseSocialMediaStrategy, account: IAccountSocialMedia
  ): FacebookSchedulePublication[] {
    // @ts-ignore
    return data.data.data.map((post) => {
      return new FacebookSchedulePublication({
        data: {
          eventName: post.message,
          eventStartTimestamp: post.scheduled_publish_time * 1000,
          eventEndTimestamp: (post.scheduled_publish_time + 60 * 60) * 1000,
          eventColor: this.mainColor,
          eventTimed: true,
          eventData: post,
          eventId: post.id,
          eventDate: ''
        },
        strategy,
        account
      })
    })
  }
}
