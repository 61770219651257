<template lang="pug">
v-card.publising_error_notification(outlined :color="color")
  v-card-title.publising_error_notification_title
    v-avatar(size='40px'): img(:src="strategy.thumbnail" contain)
    span &nbsp;
    span.text-h6.font-weight-light {{ strategy.label }}
  template(v-if="socialMediaError")
    v-card-title.publising_error_notification_title {{ socialMediaError.name }} error
    v-card-text {{ socialMediaError.description }}
  v-card-title.publising_error_notification_title(v-if="additionalErrorText") {{ additionalErrorText }}
  template(v-if="hasApiError(responseApiError)")
    v-card-title(style="padding-top: 2px; padding-bottom: 5px")
      v-btn(x-small @click.stop="showAdditionalInfo = !showAdditionalInfo") Mostrar detalles adicionales
      v-divider(style="margin-left: 7px; margin-right: 7px" vertical)
      v-btn.ml-2(
        v-if="showAdditionalInfo"
        x-small dense
        @click.stop="copyErrors"
      ) Copiar contenido
    v-card-text(v-if="showAdditionalInfo")
      .subtitle-1 {{ responseApiError.name }}
      .subtitle-2 {{ responseApiError.description }}
      .publising_error_trace
        .caption {{ responseApiError.message }}
        pre.error_trace(v-if="socialMediaError"): code {{ formatJSONTrace(socialMediaError) }}
        pre.error_trace: code {{ formatJSONTrace(responseApiError.trace) }}
</template>

<script lang="ts">
import { ApiErrorHandler, hasApiError } from '@/interfaces/ApiErrorHandler.interface'
import BaseSocialMediaStrategy, {
  ISocialMediaApiError
} from '@/store/packages/SocialPublish/BaseSocialMediaStrategy'
import Vue from 'vue'

export default Vue.extend({
  props: {
    strategy: {
      type: Object as () => BaseSocialMediaStrategy,
      required: true
    },
    responseApiError: {
      type: Object as () => ApiErrorHandler<any>,
      required: true
    },
    socialMediaError: {
      type: Object as () => ISocialMediaApiError,
      default: undefined
    },
    additionalErrorText: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'error'
    }
  },
  data () {
    return {
      showAdditionalInfo: false
    }
  },
  methods: {
    hasApiError,
    formatJSONTrace (trace: any): string {
      try {
        return JSON.stringify(trace, null, 2)
      } catch (error) {
        return trace
      }
    },
    copyErrors () {
      this.$emit('copy', {
        message: this.responseApiError.message,
        socialMediaError: this.socialMediaError,
        responseApiError: this.responseApiError.trace
      })
    }
  }
})
</script>

<style lang="sass">
.publising_error_notification
  width: 100%
  max-width: 100%
  overflow: auto

  .publising_error_notification_title
    padding-top: 7px
    padding-bottom: 7px

  .publising_error_trace
    width: 450px
    overflow-x: auto

    .error_trace
      width: 100%
</style>
