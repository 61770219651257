/**
 * Enumeración de los códigos de error de la API de Facebook.
 * Basado en la lista de errores publicada por Facebook.
 */
export enum FACEBOOK_ERROR_CODES {
  SUCCESS = 0,
  API_UNKNOWN = 1,
  API_SERVICE = 2,
  UNKNOWN_METHOD = 3,
  API_TOO_MANY_CALLS = 4,
  UNAUTHORIZED_SOURCE_IP = 5,
  HTTPS_REQUIRED = 8,
  USER_PERFORMING_TOO_MANY_ACTIONS = 9,
  API_PERMISSION_DENIED = 10,
  METHOD_DEPRECATED = 11,
  API_VERSION_DEPRECATED = 12,
  API_USER_TOO_MANY_CALLS = 17,
  SEVERAL_ISSUES = 100,
  INVALID_API_KEY = 101,
  API_SESSION = 102,
  INCORRECT_SIGNATURE = 104,
  PARAMETERS_LIMIT_EXCEEDED = 105,
  INVALID_USER_ID = 110,
  INVALID_USER_INFO_FIELD = 111,
  INVALID_USER_FIELD = 112,
  INVALID_ALBUM_ID = 120,
  INVALID_PHOTO_ID = 121,
  MALFORMED_JSON_STRING = 144,
  NOTIFICATION_NOT_DELIVERED = 180,
  ACCESS_TOKEN_EXPIRED = 190,
  /**
   * Nota: Del 200 al 299, Facebook utiliza varios códigos para denegar permisos
   * (API Permission). En este enum, se agrupa en un solo valor como referencia.
   */
  API_PERMISSION = 200,
  APPLICATION_LIMIT_REACHED = 341,
  MISSING_FILES = 324,
  TEMPORARILY_BLOCKED_FOR_VIOLATIONS = 368,
  APP_NOT_INSTALLED = 458,
  USER_CHECKPOINTED = 459,
  PASSWORD_CHANGED = 460,
  EXPIRED = 463,
  UNCONFIRMED_USER = 464,
  INVALID_ACCESS_TOKEN = 467,
  MESSAGE_CONTAINS_BANNED_CONTENT = 500,
  MISSING_MESSAGE_BODY = 501,
  MESSAGE_TOO_LONG = 502,
  INVALID_MESSAGE_RECIPIENT = 505,
  DUPLICATE_POST = 506,
  UNKNOWN_FAILURE_STORING_REF_DATA = 700,
  UNKNOWN_FACEBOOK_APP_INTEGRATION_FAILURE = 750,
  FETCH_FROM_REMOTE_SITE_FAILED = 751,
  BATCH_API_ITEM_LIMIT_EXCEEDED = 950,
  METHOD_NOT_ALLOWED_IN_BATCH_MODE = 953,
  INVALID_TIME_FOR_EVENT = 1000,
  CANNOT_CHANGE_EVENT_NAME = 1001,
  ERROR_POSTING_LINK = 1609005,
}

/**
 * Mapa que relaciona cada código de error de Facebook con su descripción
 * en español, así como un objeto `subcodes` para posibles códigos secundarios.
 */
export const FACEBOOK_ERRORS = {
  [FACEBOOK_ERROR_CODES.SUCCESS]: {
    code: FACEBOOK_ERROR_CODES.SUCCESS,
    name: 'Éxito',
    description: 'La operación se realizó correctamente.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_UNKNOWN]: {
    code: FACEBOOK_ERROR_CODES.API_UNKNOWN,
    name: 'API desconocida',
    description:
      'Puede ser un problema temporal debido a inactividad de la API. Espera y vuelve a intentar la operación. ' +
      'Si ocurre nuevamente, verifica que estés solicitando una API existente.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_SERVICE]: {
    code: FACEBOOK_ERROR_CODES.API_SERVICE,
    name: 'Servicio de la API no disponible',
    description:
      'Problema temporal debido a inactividad de la API. Espera y vuelve a intentar la operación.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.UNKNOWN_METHOD]: {
    code: FACEBOOK_ERROR_CODES.UNKNOWN_METHOD,
    name: 'Método desconocido',
    description:
      'Se llamó a un método de la API que no existe o no está permitido.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_TOO_MANY_CALLS]: {
    code: FACEBOOK_ERROR_CODES.API_TOO_MANY_CALLS,
    name: 'Demasiadas llamadas a la API',
    description:
      'Se superó el límite de uso de la API. Espera y reintenta la operación, o revisa el volumen de solicitudes.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.UNAUTHORIZED_SOURCE_IP]: {
    code: FACEBOOK_ERROR_CODES.UNAUTHORIZED_SOURCE_IP,
    name: 'Dirección IP de origen no autorizada',
    description:
      'La IP de origen no está autorizada para realizar esta llamada a la API.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.HTTPS_REQUIRED]: {
    code: FACEBOOK_ERROR_CODES.HTTPS_REQUIRED,
    name: 'Conexión HTTPS requerida',
    description:
      'Este método de la API requiere que la solicitud se realice a través de HTTPS.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.USER_PERFORMING_TOO_MANY_ACTIONS]: {
    code: FACEBOOK_ERROR_CODES.USER_PERFORMING_TOO_MANY_ACTIONS,
    name: 'Usuario realizando demasiadas acciones',
    description:
      'El usuario ha excedido el número de acciones permitidas en un período corto de tiempo.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_PERMISSION_DENIED]: {
    code: FACEBOOK_ERROR_CODES.API_PERMISSION_DENIED,
    name: 'Permiso de la API denegado',
    description:
      'El permiso no se ha otorgado o se ha revocado. Maneja la falta de permisos.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.METHOD_DEPRECATED]: {
    code: FACEBOOK_ERROR_CODES.METHOD_DEPRECATED,
    name: 'Método obsoleto',
    description: 'Este método ya no está disponible y se considera obsoleto.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_VERSION_DEPRECATED]: {
    code: FACEBOOK_ERROR_CODES.API_VERSION_DEPRECATED,
    name: 'Versión de la API obsoleta',
    description:
      'La versión de la API utilizada está obsoleta y ya no es compatible.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_USER_TOO_MANY_CALLS]: {
    code: FACEBOOK_ERROR_CODES.API_USER_TOO_MANY_CALLS,
    name: 'Demasiadas llamadas a la API (usuario)',
    description:
      'El usuario ha excedido el límite de llamadas. Espera y reintenta la operación, o revisa el volumen de solicitudes.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.SEVERAL_ISSUES]: {
    code: FACEBOOK_ERROR_CODES.SEVERAL_ISSUES,
    name: 'Varios problemas',
    description:
      'Puede deberse a parámetros inválidos, cambios en el ID de página u otros errores relacionados.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_API_KEY]: {
    code: FACEBOOK_ERROR_CODES.INVALID_API_KEY,
    name: 'Clave de API inválida',
    description: 'La clave de API proporcionada no es válida.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_SESSION]: {
    code: FACEBOOK_ERROR_CODES.API_SESSION,
    name: 'Sesión de API inválida o expirada',
    description:
      'El token de acceso o la sesión ha expirado, sido revocado o es inválido. ' +
      'Si hay un subcódigo, consúltalo para más información.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INCORRECT_SIGNATURE]: {
    code: FACEBOOK_ERROR_CODES.INCORRECT_SIGNATURE,
    name: 'Firma incorrecta',
    description: 'La firma enviada no coincide o no es válida.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.PARAMETERS_LIMIT_EXCEEDED]: {
    code: FACEBOOK_ERROR_CODES.PARAMETERS_LIMIT_EXCEEDED,
    name: 'Número de parámetros excedido',
    description:
      'La operación supera el límite máximo de parámetros permitidos.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_USER_ID]: {
    code: FACEBOOK_ERROR_CODES.INVALID_USER_ID,
    name: 'ID de usuario inválido',
    description: 'El ID de usuario proporcionado no es válido.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_USER_INFO_FIELD]: {
    code: FACEBOOK_ERROR_CODES.INVALID_USER_INFO_FIELD,
    name: 'Campo de información de usuario inválido',
    description: 'El campo solicitado para la información de usuario no existe.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_USER_FIELD]: {
    code: FACEBOOK_ERROR_CODES.INVALID_USER_FIELD,
    name: 'Campo de usuario inválido',
    description:
      'El campo solicitado para el usuario no es válido o no está permitido.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_ALBUM_ID]: {
    code: FACEBOOK_ERROR_CODES.INVALID_ALBUM_ID,
    name: 'ID de álbum inválido',
    description: 'El ID de álbum proporcionado no es válido.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_PHOTO_ID]: {
    code: FACEBOOK_ERROR_CODES.INVALID_PHOTO_ID,
    name: 'ID de foto inválido',
    description: 'El ID de la foto proporcionada no es válido.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.MALFORMED_JSON_STRING]: {
    code: FACEBOOK_ERROR_CODES.MALFORMED_JSON_STRING,
    name: 'Cadena JSON malformada',
    description: 'La cadena JSON no tiene el formato correcto.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.NOTIFICATION_NOT_DELIVERED]: {
    code: FACEBOOK_ERROR_CODES.NOTIFICATION_NOT_DELIVERED,
    name: 'Notificación no entregada',
    description: 'Hubo un error y la notificación no se pudo entregar.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.ACCESS_TOKEN_EXPIRED]: {
    code: FACEBOOK_ERROR_CODES.ACCESS_TOKEN_EXPIRED,
    name: 'Token de acceso expirado',
    description:
      'El token de acceso ha expirado. Debes obtener un nuevo token de acceso.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.API_PERMISSION]: {
    code: FACEBOOK_ERROR_CODES.API_PERMISSION,
    name: 'Permiso de la API denegado',
    description:
      'El permiso no fue otorgado o ha sido revocado. Maneja la falta de permisos (varios códigos dentro de este rango).',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.APPLICATION_LIMIT_REACHED]: {
    code: FACEBOOK_ERROR_CODES.APPLICATION_LIMIT_REACHED,
    name: 'Límite de la aplicación alcanzado',
    description:
      'Puede ser un problema temporal de inactividad o un límite de uso. Espera y reintenta la operación, ' +
      'o revisa el volumen de solicitudes.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.MISSING_FILES]: {
    code: FACEBOOK_ERROR_CODES.MISSING_FILES,
    name: 'Faltan archivos',
    description:
      'Faltan archivos en la solicitud o tienen un formato no permitido.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.TEMPORARILY_BLOCKED_FOR_VIOLATIONS]: {
    code: FACEBOOK_ERROR_CODES.TEMPORARILY_BLOCKED_FOR_VIOLATIONS,
    name: 'Bloqueado temporalmente por violaciones de políticas',
    description: 'Espera un tiempo y reintenta la operación.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.APP_NOT_INSTALLED]: {
    code: FACEBOOK_ERROR_CODES.APP_NOT_INSTALLED,
    name: 'Aplicación no instalada',
    description:
      'El usuario no ha iniciado sesión en tu aplicación. Reautentica al usuario.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.USER_CHECKPOINTED]: {
    code: FACEBOOK_ERROR_CODES.USER_CHECKPOINTED,
    name: 'Usuario en checkpoint',
    description:
      'El usuario debe iniciar sesión en Facebook para resolver un problema de su cuenta.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.PASSWORD_CHANGED]: {
    code: FACEBOOK_ERROR_CODES.PASSWORD_CHANGED,
    name: 'Contraseña cambiada',
    description:
      'Si el usuario se autenticó vía iOS, debe actualizar la contraseña en la configuración de iOS. ' +
      'De lo contrario, debe iniciar sesión de nuevo en la aplicación.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.EXPIRED]: {
    code: FACEBOOK_ERROR_CODES.EXPIRED,
    name: 'Expirado',
    description:
      'El token de acceso o la sesión ha expirado, sido revocado o es inválido. ' +
      'Es necesario manejar los tokens de acceso expirados.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.UNCONFIRMED_USER]: {
    code: FACEBOOK_ERROR_CODES.UNCONFIRMED_USER,
    name: 'Usuario sin confirmar',
    description:
      'El usuario debe iniciar sesión en Facebook para confirmar su cuenta.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_ACCESS_TOKEN]: {
    code: FACEBOOK_ERROR_CODES.INVALID_ACCESS_TOKEN,
    name: 'Token de acceso inválido',
    description:
      'El token de acceso es inválido, ha expirado o ha sido revocado. Obtén un nuevo token de acceso.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.MESSAGE_CONTAINS_BANNED_CONTENT]: {
    code: FACEBOOK_ERROR_CODES.MESSAGE_CONTAINS_BANNED_CONTENT,
    name: 'El mensaje contiene contenido prohibido',
    description:
      'No se permite el envío de este contenido. Revisa las políticas de Facebook.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.MISSING_MESSAGE_BODY]: {
    code: FACEBOOK_ERROR_CODES.MISSING_MESSAGE_BODY,
    name: 'Falta el cuerpo del mensaje',
    description: 'No se ha proporcionado texto o cuerpo en el mensaje.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.MESSAGE_TOO_LONG]: {
    code: FACEBOOK_ERROR_CODES.MESSAGE_TOO_LONG,
    name: 'Mensaje demasiado largo',
    description: 'El contenido del mensaje excede el límite de longitud permitido.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_MESSAGE_RECIPIENT]: {
    code: FACEBOOK_ERROR_CODES.INVALID_MESSAGE_RECIPIENT,
    name: 'Destinatario de mensaje inválido',
    description: 'El ID o la referencia del destinatario no es válida.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.DUPLICATE_POST]: {
    code: FACEBOOK_ERROR_CODES.DUPLICATE_POST,
    name: 'Publicación duplicada',
    description:
      'No se pueden publicar consecutivamente mensajes o posts idénticos. Cambia el contenido y reintenta.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.UNKNOWN_FAILURE_STORING_REF_DATA]: {
    code: FACEBOOK_ERROR_CODES.UNKNOWN_FAILURE_STORING_REF_DATA,
    name: 'Fallo desconocido al almacenar datos de referencia',
    description: 'Se produjo un error interno al almacenar la información. Reintenta.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.UNKNOWN_FACEBOOK_APP_INTEGRATION_FAILURE]: {
    code: FACEBOOK_ERROR_CODES.UNKNOWN_FACEBOOK_APP_INTEGRATION_FAILURE,
    name: 'Fallo de integración desconocido con la aplicación de Facebook',
    description: 'Fallo interno de la aplicación de Facebook. Vuelve a intentar más tarde.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.FETCH_FROM_REMOTE_SITE_FAILED]: {
    code: FACEBOOK_ERROR_CODES.FETCH_FROM_REMOTE_SITE_FAILED,
    name: 'Error al obtener datos del sitio remoto',
    description:
      'La solicitud para obtener datos externos falló. Verifica la URL o la conectividad de red.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.BATCH_API_ITEM_LIMIT_EXCEEDED]: {
    code: FACEBOOK_ERROR_CODES.BATCH_API_ITEM_LIMIT_EXCEEDED,
    name: 'Se excedió el límite de elementos en la llamada en lote',
    description:
      'Cada llamada en batch no puede contener más de 20 elementos. Ajusta la petición.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.METHOD_NOT_ALLOWED_IN_BATCH_MODE]: {
    code: FACEBOOK_ERROR_CODES.METHOD_NOT_ALLOWED_IN_BATCH_MODE,
    name: 'Método no permitido en modo batch',
    description: 'El método llamado no está soportado dentro de una llamada en lote.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.INVALID_TIME_FOR_EVENT]: {
    code: FACEBOOK_ERROR_CODES.INVALID_TIME_FOR_EVENT,
    name: 'Hora inválida para el evento',
    description: 'La fecha u hora del evento proporcionada no es válida.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.CANNOT_CHANGE_EVENT_NAME]: {
    code: FACEBOOK_ERROR_CODES.CANNOT_CHANGE_EVENT_NAME,
    name: 'No es posible cambiar el nombre del evento',
    description:
      'Ya no se permite modificar el nombre de este evento. Revisa las políticas de eventos de Facebook.',
    subcodes: {}
  },
  [FACEBOOK_ERROR_CODES.ERROR_POSTING_LINK]: {
    code: FACEBOOK_ERROR_CODES.ERROR_POSTING_LINK,
    name: 'Error al publicar el enlace',
    description:
      'Ocurrió un problema al extraer datos del enlace proporcionado. Verifica la URL y reintenta.',
    subcodes: {}
  }
}

export enum FACEBOOK_PROGRAMING_ERROR_CODE {
  IMAGE_LIMIT_EXCEEDED = 1,
  VIDEO_LIMIT_EXCEEDED = 2,
  UNCOMPATIBLE_MEDIA_MIX = 3,
  CANT_GET_MEDIA_ID = 4,
  NO_ASSETS_FOUND = 5
}

export const FACEBOOK_PROGRAMING_ERROR_CODES = {
  [FACEBOOK_PROGRAMING_ERROR_CODE.IMAGE_LIMIT_EXCEEDED]: {
    code: FACEBOOK_PROGRAMING_ERROR_CODE.IMAGE_LIMIT_EXCEEDED,
    name: 'Límite de imágenes excedido',
    description: 'No se pueden publicar imagenes en un mismo post.',
    subcodes: {}
  },
  [FACEBOOK_PROGRAMING_ERROR_CODE.VIDEO_LIMIT_EXCEEDED]: {
    code: FACEBOOK_PROGRAMING_ERROR_CODE.VIDEO_LIMIT_EXCEEDED,
    name: 'Límite de videos excedido',
    description: 'No se pueden publicar videos en un mismo post.',
    subcodes: {}
  },
  [FACEBOOK_PROGRAMING_ERROR_CODE.UNCOMPATIBLE_MEDIA_MIX]: {
    code: FACEBOOK_PROGRAMING_ERROR_CODE.UNCOMPATIBLE_MEDIA_MIX,
    name: 'Múltiples medios no compatibles',
    description:
      'No se pueden publicar imagenes y videos en un mismo post. Cambia el contenido y reintenta.',
    subcodes: {}
  },
  [FACEBOOK_PROGRAMING_ERROR_CODE.CANT_GET_MEDIA_ID]: {
    code: FACEBOOK_PROGRAMING_ERROR_CODE.CANT_GET_MEDIA_ID,
    name: 'No se pudo obtener el ID del medio',
    description: 'No se pudo obtener el ID del medio. Revisa la URL.',
    subcodes: {}
  },
  [FACEBOOK_PROGRAMING_ERROR_CODE.NO_ASSETS_FOUND]: {
    code: FACEBOOK_PROGRAMING_ERROR_CODE.NO_ASSETS_FOUND,
    name: 'No se encontraron recursos',
    description: 'No se encontraron recursos para publicar.',
    subcodes: {}
  }
}

export enum FACEBOOK_ERROR_TYPE {
  FACEBOOK_API_ERROR = 2000,
  FACEBOOK_PROGRAMING_ERROR = 2001
}
