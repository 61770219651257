var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("tabs"), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loading-overlay"
  }, [_c('div', {
    staticClass: "loading-spinner"
  }, [_c('v-progress-circular', {
    attrs: {
      "size": _vm.spinnerSize,
      "width": _vm.spinnerWidth,
      "color": _vm.spinnerColor,
      "indeterminate": ""
    }
  }), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.loadingText ? _c('div', {
    staticClass: "loading-text mt-2"
  }, [_vm._v(" " + _vm._s(_vm.loadingText) + " ")]) : _vm._e()])], 1)]) : _vm._e()]), _c('div', {
    class: [_vm.$slots.tabs ? 'wrapper_container_tabs' : 'wrapper_container', {
      'content-loading': _vm.loading && _vm.fadeContent
    }, {
      'no-scroll': _vm.noScroll
    }]
  }, [_c('transition', {
    attrs: {
      "name": "fade-slide",
      "mode": "out-in"
    }
  }, [_c('div', {
    key: _vm.loadingKey
  }, [_vm._t("default")], 2)])], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }