import { SOCIAL_MEDIA_TYPE } from '@/store/catalogs/SOCIAL_MEDIA_TYPE'
import BaseSocialMediaStrategy, {
  ISocialMediaApiError
} from './BaseSocialMediaStrategy'
import { ApiErrorHandler } from '@/interfaces/ApiErrorHandler.interface'
import { IFacebookApiError } from '@/interfaces/Facebook.interface'
import {
  FACEBOOK_ERROR_TYPE,
  FACEBOOK_ERRORS,
  FACEBOOK_PROGRAMING_ERROR_CODES
} from '@/store/catalogs/FACEBOOK_GRAPHAPI_ERRORS'
import validateInstagramAspectRatio from '@/utils/validateInstagramAspectRatio'
import { trycatch } from '@/utils/trycatch'
import InstagramSchedulePublication from '../SocialPublishScheduled/InstagramSchedulePublication'
import { PublicationSocialMedia } from '@/store/interfaces/PublicationSocialMedia.interface'
import { DateTime } from 'luxon'
import { IAccountSocialMedia } from '@/store/interfaces/SocialAccounts.interface'

export class InstagramSocialStrategy extends BaseSocialMediaStrategy {
  type: SOCIAL_MEDIA_TYPE;
  limitKBPostImageSize: number;
  limitKBPostVideoSize: number;
  allowedMimeTypes: Set<string>;
  mainColor: string;

  constructor () {
    super()
    this.type = SOCIAL_MEDIA_TYPE.INSTAGRAM
    this.limitKBPostImageSize = 1024 * 30
    this.limitKBPostVideoSize = 1024 * 100
    this.allowedMimeTypes = new Set([
      // Tipos MIME para Imágenes
      'image/jpeg', // JPEG (.jpg, .jpeg)
      'image/png', // PNG (.png)

      // Tipos MIME para Videos
      'video/mp4', // MP4 (.mp4)
      'video/quicktime' // MOV (.mov)
    ])
    this.mainColor = '#C1358467'
  }

  async getErrors (files: File[]) {
    const imageFiles = files.filter((file) => file.type.startsWith('image'))
    const videoFiles = files.filter((file) => file.type.startsWith('video'))

    const haveInvalidFilesType = {
      rule: this.getInvalidFiles(files).length > 0,
      message: 'Instagram solo permite publicar imagenes o videos.'
    }
    const haveMoreThanAvaliableFiles = {
      rule: files.length > 10,
      message: `Instagram solo permite publicar hasta 10 archivos en un solo post. Archivos seleccionados: ${files.length} archivos.`
    }
    const notHaveAtLeastSomeVideoOrImage = {
      rule: imageFiles.length === 0 && videoFiles.length === 0,
      message: 'Instagram debe tener al menos un video o una imagen.'
    }
    const isSomeImageWithInvalidAspectRatio = {
      rule: (
        await Promise.all(
          imageFiles.map((file) =>
            trycatch(() => validateInstagramAspectRatio(file), false)
          )
        )
      ).some((image) => image.valid === false),
      message: 'Instagram solo permite imagenes con un aspect ratio de 1:1.'
    }

    return [
      notHaveAtLeastSomeVideoOrImage,
      isSomeImageWithInvalidAspectRatio,
      haveMoreThanAvaliableFiles,
      haveInvalidFilesType
    ]
  }

  getApiError (
    params: ApiErrorHandler<any, IFacebookApiError>
  ): ISocialMediaApiError | undefined {
    switch (params.error) {
      case FACEBOOK_ERROR_TYPE.FACEBOOK_API_ERROR: {
        if (!params.trace?.error?.code) return
        const error =
          FACEBOOK_ERRORS[
            params.trace?.error?.code as keyof typeof FACEBOOK_ERRORS
          ]
        return error
      }
      case FACEBOOK_ERROR_TYPE.FACEBOOK_PROGRAMING_ERROR: {
        if (!params.suberror) return
        const error =
          FACEBOOK_PROGRAMING_ERROR_CODES[
            params.suberror as keyof typeof FACEBOOK_PROGRAMING_ERROR_CODES
          ]
        return error
      }
    }
  }

  showAssetsTextArea (files: File[]): boolean {
    if (files.length > 1) return true
    return false
  }

  public serializeSchedulePublicationData (
    data: ApiErrorHandler<
      PublicationSocialMedia<{ creation_id: string; message: string; media_type: string }>[]
    >,
    strategy: BaseSocialMediaStrategy,
    account: IAccountSocialMedia
  ): InstagramSchedulePublication[] {
    return data.data.map((post) => {
      return new InstagramSchedulePublication({
        data: {
          eventName: post.psm_data.message || post.publication_files.at(0)?.fl_description || '',
          eventStartTimestamp: DateTime.fromISO(post.psm_publication_date).toMillis(),
          eventEndTimestamp: DateTime.fromISO(post.psm_publication_date).plus({ hours: 1 }).toMillis(),
          eventColor: this.mainColor,
          eventTimed: true,
          eventData: post,
          eventId: post.id_publication_social_media,
          eventDate: ''
        },
        strategy,
        account
      })
    })
  }
}
