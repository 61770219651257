const noteCreditRoutes = {
  meta: {
    title: 'Notas de credito'
  },
  path: 'note-credit',
  component: () => import('@/components/Facturacion/modules/note_credit/NoteCredit.vue'),
  children: [
    {
      meta: {
        title: 'Notas de credito'
      },
      path: '',
      name: 'note-credit',
      component: () => import('@/components/Facturacion/modules/note_credit/NoteCreditList.vue')
    },
    {
      path: 'nueva-note-credit',
      name: 'nueva-note-credit',
      meta: {
        title: 'Nueva nota de credito'
      },
      component: () => import('@/components/Facturacion/modules/note_credit/NoteCreditForm.vue')
    }
  ]
}

export default noteCreditRoutes
