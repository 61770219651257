import { State } from './state'

export default {
  formFilesWithURLImage (state: State) {
    return state.publishingPreviewData.form.files.map(file => {
      return {
        ...file,
        objectURL: URL.createObjectURL(file.file)
      }
    })
  },
  currentSelectedFilesObjs (state: State) {
    return state.publishingPreviewData.form.files.map(file => file.file)
  },
  hasEmptyContent (state: State) {
    return state.publishingPreviewData.form.message === '' && state.publishingPreviewData.form.files.length === 0
  }
}
