import Vue from 'vue'
import { IAccountSocialMedia } from '../interfaces/SocialAccounts.interface'
import { State } from './state'

export default {
  SET_STATE (state: State, payload: typeof state) {
    Object.assign(state, payload)
  },
  SET_SOCIAL_ACCOUNTS (state: State, payload: IAccountSocialMedia[]) {
    state.socialAccounts = payload
  },
  ADD_SOCIAL_ACCOUNTS (state: State, payload: IAccountSocialMedia[]) {
    state.socialAccounts = [...state.socialAccounts, ...payload]
  },
  UPDATE_SOCIAL_ACCOUNT (state: State, payload: { index: number, item: IAccountSocialMedia }) {
    Vue.set(state.socialAccounts, payload.index, payload.item)
  },
  DELETE_SOCIAL_ACCOUNT (state: State, index: number) {
    state.socialAccounts.splice(index, 1)
  },
  DELETE_SELECTED_SOCIAL_ACCOUNT_BY_INDEX (state: State, index: number) {
    state.publishingPreviewData.selectedAccounts.splice(index, 1)
  },
  ADD_PUBLISH_FORM_FILE (state: State, payload: { caption: string, file: File }) {
    state.publishingPreviewData.form.files.push({
      id: Number(`${Date.now()}${payload.file.size}`),
      ...payload
    })
  },
  REMOVE_PUBLISH_BY_INDEX (state: State, index: number) {
    state.publishingPreviewData.form.files.splice(index, 1)
  },
  SET_PUBLISH_STATE (state: State, payload: typeof state.publishingPreviewData) {
    Object.assign(state.publishingPreviewData, payload)
  },
  SET_PUBLISH_FORM_STATE (state: State, payload: typeof state.publishingPreviewData.form) {
    Object.assign(state.publishingPreviewData.form, payload)
  },
  REMOVE_SCHEDULE_PUBLICATION (state: State, index: number) {
    state.schedulePublicationEvents.splice(index, 1)
  }
}
