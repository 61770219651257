import {
  IAccountSocialMedia,
  IAccountSocialMediaFacebookAndInstagramData,
  IPublishingFileItem
} from '@/store/interfaces/SocialAccounts.interface'
import BaseSchedulePublication from './BaseSchedulePublication'
import SchedulePublicationEvent from './SchedulePublicationEvent.interface'
import BaseSocialMediaStrategy from '../SocialPublish/BaseSocialMediaStrategy'
import { SchedulePosts } from '@/interfaces/Facebook.interface'
import { DateTime } from 'luxon'

export default class FacebookSchedulePublication extends BaseSchedulePublication {
  publicationScheduledDate: string;
  publicationScheduledCreation: string;
  account!: IAccountSocialMedia<IAccountSocialMediaFacebookAndInstagramData>;
  eventData!: SchedulePosts;

  constructor (params: {
    data: SchedulePublicationEvent;
    strategy: BaseSocialMediaStrategy;
    account: IAccountSocialMedia;
  }) {
    super(params)
    this.strategy = params.strategy
    this.account = params.account
    this.publicationScheduledDate = this.getNaturalFormatedDate({
      isoDate: DateTime.fromMillis(
        this.eventData.scheduled_publish_time * 1000
      ).toISO(),
      // @ts-ignore
      offset: 0
    })
    this.publicationScheduledCreation = ''
  }

  public onInitInstance (): void {
    // ---
  }

  get accountName (): string {
    return this.account.asm_data.name
  }

  async getFiles (): Promise<IPublishingFileItem[]> {
    if (!this.eventData.attachments?.data) return []

    const filesPromises = this.eventData.attachments.data.map(async (attachment) => {
      // Si el attachment es un "álbum" (varias fotos), revisamos subattachments
      if (attachment.type === 'album' && attachment.subattachments?.data) {
        const subattachments = []
        for (const sub of attachment.subattachments.data) {
          const src = sub.media?.image?.src
          if (src) {
            const response = await fetch(src)
            const blob = await response.blob()
            const file = new File([blob], 'image.jpg')
            subattachments.push(
              {
                id: 0,
                caption: '',
                file
              }
            )
          }
        }
        return subattachments
      } else if (
        attachment.type === 'video_inline' &&
        attachment.media?.source
      ) {
        // Si es un video
        const videoUrl = attachment.media.source
        const response = await fetch(videoUrl)
        const blob = await response.blob()
        const file = new File([blob], 'video.mp4', {
          type: 'video/mp4'
        })
        return [
          {
            id: 0,
            file,
            caption: ''
          }
        ]
      } else if (attachment.media?.image?.src) {
        // Si es una sola imagen
        const imageUrl = attachment.media.image.src
        const response = await fetch(imageUrl)
        const blob = await response.blob()
        const file = new File([blob], getFileNameFromUrl(imageUrl))
        return [{
          id: 0,
          file,
          caption: ''
        }]
      }

      return []
    })

    const files = (await Promise.all(filesPromises)).flat()
    return files
  }

  getDeletionData () {
    return {
      post_id: this.eventData.id,
      id_account_social_media: this.account.id_account_social_media
    }
  }
}

/**
 * Toma el último segmento de la URL para usarlo como nombre de archivo.
 * Ejemplo: 'https://ejemplo.com/ruta/image.jpg' => 'image.jpg'
 */
function getFileNameFromUrl (url: string): string {
  return url.split('/').pop() || 'file'
}
