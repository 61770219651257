// import { InventoryState } from '../../state'

import { NoteCreditState } from './state'

// import { ProductState } from './state'

export default {
  getPagos (state: NoteCreditState) {
    return state.pagos
  }
}
