// limitDirectives.ts
import Vue, { DirectiveOptions, VNode } from 'vue'

// Función auxiliar para obtener un valor de una ruta de objetos anidados
function getNestedValue (obj: any, path: string): any {
  return path.split('.').reduce((o, p) => (o && o[p] !== undefined) ? o[p] : undefined, obj)
}

// Función auxiliar para establecer un valor en una ruta de objetos anidados
function setNestedValue (obj: any, path: string, value: any, vnode: VNode): void {
  const parts = path.split('.')
  const lastKey = parts.pop()

  if (!lastKey) return

  // Construir la ruta de objetos anidados si no existe
  let current = obj
  for (const part of parts) {
    if (!current[part]) {
      if (vnode.context) {
        vnode.context.$set(current, part, {})
      } else {
        current[part] = {}
      }
    }
    current = current[part]
  }

  // Establecer el valor final
  if (vnode.context) {
    vnode.context.$set(current, lastKey, value)
  } else {
    current[lastKey] = value
  }
}

interface LimitNumberPathOptions {
  object: any;
  valuePath: string; // Ruta al valor a limitar (por ejemplo, 'detail.descuento')
  maxPath: string; // Ruta al valor máximo (por ejemplo, 'factura_relacionado.saldo')
  resultPath?: string; // Ruta donde guardar el resultado (por ejemplo, 'detail.nuevo_saldo')
  min?: number; // Valor mínimo permitido
}

interface LimitNumberPathBinding {
  value: LimitNumberPathOptions;
}
// Modificación de la directiva v-limit-number-path en directives.ts.z
export const LimitNumberPath: DirectiveOptions = {
  // @ts-ignore
  bind (el: HTMLElement, binding: LimitNumberPathBinding, vnode: VNode) {
    const inputEl = el.querySelector('input')
    if (!inputEl) return

    // Establecer explícitamente el tipo como text
    inputEl.setAttribute('type', 'text')

    const options = binding.value
    inputEl.setAttribute('min', (options.min || 0).toString())

    // Flag para evitar recursión
    let isProcessing = false

    // Función principal para procesar la entrada
    const processInput = (e: Event) => {
      if (isProcessing) return
      isProcessing = true

      try {
        const target = e.target as HTMLInputElement
        const obj = options.object

        // Obtener el valor máximo usando la ruta
        const max = parseFloat(getNestedValue(obj, options.maxPath)?.toString() || '0')
        const min = options.min || 0

        // Obtener el valor actual
        const inputValue = target.value

        // Si el valor termina con un punto, permitirlo temporalmente
        if (inputValue.endsWith('.')) {
          // Solo permitir un punto
          if ((inputValue.match(/\./g) || []).length > 1) {
            target.value = inputValue.substring(0, inputValue.lastIndexOf('.'))
          }
          // No realizar más validaciones en este punto
          return
        }

        // Limpiar y validar el valor
        let cleanValue = inputValue.replace(/[^0-9.]/g, '')

        // Asegurar solo un punto decimal
        const parts = cleanValue.split('.')
        if (parts.length > 2) {
          cleanValue = parts[0] + '.' + parts.slice(1).join('')
        }

        // Limitar a dos decimales
        if (parts.length === 2 && parts[1].length > 2) {
          cleanValue = parts[0] + '.' + parts[1].substring(0, 2)
        }

        // Actualizar el valor en el input si ha cambiado
        if (target.value !== cleanValue) {
          target.value = cleanValue
        }

        // Solo aplicar validaciones de límites si el valor no está vacío y no termina con punto
        if (cleanValue !== '' && !cleanValue.endsWith('.')) {
          const numValue = parseFloat(cleanValue)

          if (!isNaN(numValue)) {
            if (numValue < min) {
              target.value = min.toString()
              setNestedValue(obj, options.valuePath, min, vnode)
            } else if (numValue > max) {
              target.value = max.toString()
              setNestedValue(obj, options.valuePath, max, vnode)
            } else {
              setNestedValue(obj, options.valuePath, numValue, vnode)
            }

            // Calcular resultado
            if (options.resultPath) {
              const result = max - numValue
              setNestedValue(obj, options.resultPath, result, vnode)
            }
          }
        } else if (cleanValue === '') {
          // Si el valor está vacío, establecer el valor mínimo
          setNestedValue(obj, options.valuePath, min, vnode)

          if (options.resultPath) {
            setNestedValue(obj, options.resultPath, max, vnode)
          }
        }
      } finally {
        isProcessing = false
      }
    }

    // Evento keyup para capturar el punto decimal inmediatamente
    inputEl.addEventListener('keyup', (e: KeyboardEvent) => {
      // Permitir el punto decimal
      if (e.key === '.') {
        e.preventDefault()
        const cursorPos = (e.target as HTMLInputElement).selectionStart || 0
        const value = (e.target as HTMLInputElement).value

        // Verificar si ya hay un punto decimal
        if (value.indexOf('.') !== -1 && value.indexOf('.') !== cursorPos - 1) {
          // Si ya hay un punto decimal, no agregar otro

        }
      }
    })

    // Usar blur para la validación final
    inputEl.addEventListener('blur', processInput)

    // Usar input para la validación en tiempo real
    inputEl.addEventListener('input', processInput)
  }
}
