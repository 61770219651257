var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapContainer', {
    staticClass: "relative-container"
  }, [_vm._t("content"), _c('v-fade-transition', [_vm.loading ? _c('div', {
    staticClass: "overlay"
  }, [_c('span', {
    staticClass: "mb-2 font-weight-bold red--text text-h6"
  }, [_vm._v("Cargando " + _vm._s(_vm.text) + "...")]), _c('div', {
    staticClass: "loader"
  })]) : _vm._e()])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }