
import { ActionContext } from 'vuex'
// import { State } from './state'
const URL_ACTION = '/apis/user/account/'

const actions = {
  async loadGroupByAccount ({ dispatch }: ActionContext<unknown, any>, id_account:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id_account}/groups/`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async updateGroup ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getMembers ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/members/`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async addMembers ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/members/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async removeMembers ({ dispatch }: ActionContext<unknown, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/members/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json' // Asegurar JSON si es necesario
        },
        data: { user_ids: payload.user_ids } // Enviar array en el cuerpo
      },
      { root: true }
    )
    return data
  },
  async createGroup ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async deleteGroup ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getByIdRole ({ dispatch }: ActionContext<unknown, any>, payload: any) {
    console.log(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/${payload.id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async createRole ({ dispatch }: ActionContext<unknown, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async loadRoles ({ dispatch }: ActionContext<unknown, any>, id_account:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id_account}/roles/`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async updateRole ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/${payload.id}/`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async deleteRole ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/${payload.id}/`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async savePermissionsRole ({ dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account_id}/roles/${payload.role_id}/permissions/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async loadSocialReasons ({ dispatch }: ActionContext<unknown, any>, id_account:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id_account}/social_reasons/`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getUnitsGroup ({ dispatch }: ActionContext<unknown, any>, payload: { id_group: number; id_account: number; }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id_account}/groups/${payload.id_group}/units/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json' // Asegurar JSON si es necesario
        }
      },
      { root: true }
    )
    return data
  },
  async createUnitsGroup ({ dispatch }: ActionContext<unknown, any>, { id_account, id_group, ...rest }: { id_group: number; id_account: number; ids_units: number[]; }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id_account}/groups/${id_group}/units/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // Asegurar JSON si es necesario
        },
        data: rest
      },
      { root: true }
    )
    return data
  },
  async deleteUnitsGroup ({ dispatch }: ActionContext<unknown, any>, { id_account, id_group, ...rest }: { id_group: number; id_account: number; ids_units: number[]; }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id_account}/groups/${id_group}/units/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // Asegurar JSON si es necesario
        },
        data: rest
      },
      { root: true }
    )
    return data
  }
}
export default actions
