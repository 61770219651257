export enum SOCIAL_MEDIA_TYPE {
  FACEBOOK = 1,
  INSTAGRAM = 2
}

export const SOCIAL_MEDIA_TYPE_LABELS = {
  [SOCIAL_MEDIA_TYPE.FACEBOOK]: 'Facebook',
  [SOCIAL_MEDIA_TYPE.INSTAGRAM]: 'Instagram'
}

export const SOCIAL_MEDIA_TYPE_ARRAY = [
  {
    label: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPE.FACEBOOK],
    id: SOCIAL_MEDIA_TYPE.FACEBOOK
  },
  {
    label: SOCIAL_MEDIA_TYPE_LABELS[SOCIAL_MEDIA_TYPE.INSTAGRAM],
    id: SOCIAL_MEDIA_TYPE.INSTAGRAM
  }
]

export const SOCIAL_MEDIA_TYPE_THUMBNAILS = {
  [SOCIAL_MEDIA_TYPE.FACEBOOK]: require('@/assets/svg/facebook.svg'),
  [SOCIAL_MEDIA_TYPE.INSTAGRAM]: require('@/assets/svg/instagram.svg')
}
