import { ActionContext } from 'vuex'
import { State } from './state'
import { fbApiAsync, fbLoginAsync } from '@/utils/facebookAsyncWrappers'
import { IFBInstagramAccountData, IFBPage } from '@/interfaces/Facebook.interface'
import Vue from 'vue'

export default {
  async init ({ rootState, dispatch, state, commit }: ActionContext<State, any>) {
    const IS_DEV = rootState.app.IS_DEV

    if (!state.isSDKloaded) {
      try {
        // @ts-ignore
        await Vue.loadScript('//connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v21.0')
        commit('SET_STATE', { isSDKloaded: true })
        if (IS_DEV) console.log('FACEBOOK_SDK CARGADO')
      } catch (e) {
        dispatch('app/addLogWithError', { title: 'LOAD_FACEBOOK_SDK', color: 'error', message: '', error: e }, { root: true })
        dispatch('app/showNotification', {
          content: 'No se pudo cargar el SDK de Facebook, revise su configuración de red o proxy',
          type: 'error',
          timeout: false
        },
        { root: true })
        console.error(e)
        if (IS_DEV) console.log('FACEBOOK_SDK NO CARGADO')
        return
      }
    }

    const FACEBOOK_APP_ID = process.env.VUE_APP_FACEBOOK_APP_ID
    if (IS_DEV) console.log('FACEBOOK_APP_ID', FACEBOOK_APP_ID)
    if (!FACEBOOK_APP_ID) return

    FB.init({
      appId: FACEBOOK_APP_ID,
      xfbml: true,
      version: 'v21.0'
    })
  },
  async login (): Promise<void> {
    await fbLoginAsync({ scope: 'pages_manage_posts,pages_read_engagement,pages_show_list,instagram_basic,instagram_content_publish' })
  },
  async getUserPages (): Promise<IFBPage[]> {
    const response = await fbApiAsync('/me/accounts', 'get', { fields: 'id,name,picture,access_token' })
    return response.data
  },
  async getInstagramBusinessAccountsDataByPages (_: ActionContext<State, any>, payload: IFBPage[]): Promise<Record<string, IFBInstagramAccountData>> {
    const mapPageObj = new Map<string, IFBPage>(payload.map(page => [page.id, page]))
    const promises = payload.map(({ id, access_token }) => fbApiAsync(`/${id}?fields=instagram_business_account`, 'get', { access_token }))
    const responses = await Promise.all(promises)
    // @ts-ignore
    const instagramBusinessAccounts: Record<string, IFBInstagramAccountData> = responses.reduce(async (acc: Record<string, any>, response) => {
      if (!response.instagram_business_account) return acc
      const access_token = mapPageObj.get(response.id)?.access_token
      if (!access_token) return acc

      const instagramAccountData = await fbApiAsync(`/${response.instagram_business_account.id}`, 'get', {
        access_token,
        fields: 'id,username,profile_picture_url,website,ig_id'
      })

      acc[response.id] = instagramAccountData

      return acc
    }, {} as Record<string, any>)

    return instagramBusinessAccounts
  },
  async getPagesWithInstagramBusinessAccountData ({ dispatch }: ActionContext<State, any>): Promise<IFBPage[]> {
    const pages: IFBPage[] = await dispatch('getUserPages')
    const instagramBusinessAccounts: Record<string, IFBInstagramAccountData> = await dispatch('getInstagramBusinessAccountsDataByPages', pages)
    return pages.map(page => ({ ...page, instagram: instagramBusinessAccounts[page.id] || null }))
  },
  async makeFacebookPost (context: ActionContext<State, any>, { pageId, ...payload }: { message: string; link?: string; picture?: string; pageId: string; access_token: string; }) {
    await fbApiAsync(`/${pageId}/feed`, 'post', payload)
  }
}
