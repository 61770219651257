import { ActionContext } from 'vuex'
import { IAccountSocialMedia } from '../interfaces/SocialAccounts.interface'
import { trycatchAsync } from '@/utils/trycatch'
import objToFormData from '@/utils/objToFormDataDeep'
import { State } from './state'
import { ApiErrorHandler, hasApiError } from '@/interfaces/ApiErrorHandler.interface'
import { DateTime } from 'luxon'
import PublishErrorNotification from '@/components/SocialMedia/utils/PublishErrorNotification.vue'
import FactorySocialStrategy from '../packages/SocialPublish/FactorySocialStrategy'
import BaseSchedulePublication from '../packages/SocialPublishScheduled/BaseSchedulePublication'

export default {
  async init (context: ActionContext<State, any>) {
    // @ts-ignore
    // Cada vez que cambie la lista de cuentas, se actualizan los strategy
    this._vm.$watch(
      () => context.state.publishingPreviewData.selectedAccounts,
      async (selectedAccounts: IAccountSocialMedia[]) => {
        context.state.publishingPreviewData.strategyAccountSocialMediaManager.setAccounts(
          selectedAccounts
        )
      }
    )
    // @ts-ignore
    // Cada vez que cambie la lista de archivos, se validan en el strategy para ver si estan validos
    this._vm.$watch(
      () => context.getters.currentSelectedFilesObjs,
      async (files: File[]) => {
        context.state.publishingPreviewData.strategyAccountSocialMediaManager.showAssetsTextArea(
          files
        )
        await context.state.publishingPreviewData.strategyAccountSocialMediaManager.validateFiles(
          files
        )
      }
    )
    // @ts-ignore
    // Cada que se cambie la cantidad de estrategias, se validan los archivos en el strategy para ver si estan validos
    this._vm.$watch(
      () =>
        context.state.publishingPreviewData.strategyAccountSocialMediaManager
          .currentStrategiesCount,
      async () => {
        context.state.publishingPreviewData.strategyAccountSocialMediaManager.showAssetsTextArea(
          context.getters.currentSelectedFilesObjs
        )
        await context.state.publishingPreviewData.strategyAccountSocialMediaManager.validateFiles(
          context.getters.currentSelectedFilesObjs
        )
      }
    )

    await Promise.all([
      trycatchAsync(
        async () => await context.dispatch('getAllAvaliableSocialAccounts'),
        null
      ),
      trycatchAsync(async () => await context.dispatch('facebook/init'), null)
    ])
  },
  async createSocialAccountItem (
    { dispatch, commit }: ActionContext<any, any>,
    payload: any
  ): Promise<ApiErrorHandler<IAccountSocialMedia[]>> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('ADD_SOCIAL_ACCOUNTS', data.data)

      return data
    } catch (error) {
      console.error(error)
      dispatch(
        'app/addLogWithError',
        { title: 'CREATE_SOCIAL_ACCOUNT', color: 'error', message: '', error },
        { root: true }
      )
      throw error
    }
  },
  async updateSocialAccountItem (
    { dispatch, commit, state }: ActionContext<any, any>,
    payload: any
  ): Promise<ApiErrorHandler<IAccountSocialMedia>> {
    try {
      const { data }: { data: ApiErrorHandler<IAccountSocialMedia> } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      if (!hasApiError(data)) {
        const index = state.socialAccounts.findIndex(
          (p: IAccountSocialMedia) =>
            p.id_account_social_media === data.data.id_account_social_media
        )

        if (index !== -1) {
          commit('UPDATE_SOCIAL_ACCOUNT', { index, item: data.data })
        }
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch(
        'app/addLogWithError',
        { title: 'UPDATE_SOCIAL_ACCOUNT', color: 'error', message: '', error },
        { root: true }
      )
      throw error
    }
  },
  async deleteSocialAccountItem (
    { dispatch, commit, state }: ActionContext<any, any>,
    id_account_social_media: number
  ): Promise<void> {
    try {
      await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/',
          method: 'DELETE',
          data: { id_account_social_media }
        },
        { root: true }
      )
      const index = state.socialAccounts.findIndex(
        (p: IAccountSocialMedia) =>
          p.id_account_social_media === id_account_social_media
      )
      if (index === -1) return

      commit('DELETE_SOCIAL_ACCOUNT', index)
    } catch (error) {
      console.error(error)
      dispatch(
        'app/addLogWithError',
        { title: 'DELETE_SOCIAL_ACCOUNT', color: 'error', message: '', error },
        { root: true }
      )
      throw error
    }
  },
  async deletePublication (
    { dispatch }: ActionContext<any, any>,
    payload: any
  ): Promise<ApiErrorHandler<any>> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/publish_delete',
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )

      return data as ApiErrorHandler<any>
    } catch (error) {
      console.error(error)
      dispatch(
        'app/addLogWithError',
        { title: 'DELETE_PUBLICATION', color: 'error', message: '', error },
        { root: true }
      )
      throw error
    }
  },
  async removeSchedulePublication ({ state, commit }: ActionContext<State, any>, payload: BaseSchedulePublication) {
    const index = state.schedulePublicationEvents.findIndex(
      (p: BaseSchedulePublication) => p.eventId === payload.eventId
    )

    if (index === -1) return
    commit('REMOVE_SCHEDULE_PUBLICATION', index)
  },
  async getAllAvaliableSocialAccounts ({
    commit,
    dispatch
  }: ActionContext<any, any>): Promise<ApiErrorHandler<IAccountSocialMedia[]>> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_SOCIAL_ACCOUNTS', data.data)

      return data
    } catch (error) {
      console.error(error)
      dispatch(
        'app/addLogWithError',
        { title: 'GET_SOCIAL_ACCOUNTS', color: 'error', message: '', error },
        { root: true }
      )
      throw error
    }
  },
  async publishSocialAccountPost ({
    state,
    dispatch
  }: ActionContext<State, any>): Promise<
    ApiErrorHandler<{ [key: string]: ApiErrorHandler<any> }>
  > {
    try {
      const payload = {
        ...state.publishingPreviewData.form,
        publication_time: state.publishingPreviewData.form.publication_time
          ? DateTime.fromJSDate(
            state.publishingPreviewData.form.publication_time
          )
            .toUTC()
            .toISO({ includeOffset: false })
          : null,
        ids_account_social_media:
          state.publishingPreviewData.selectedAccounts.map(
            (account) => account.id_account_social_media
          )
      }
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/publish',
          method: 'POST',
          data: objToFormData(payload, null, '')
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch(
        'app/addLogWithError',
        { title: 'PUBLISH_SOCIAL_ACCOUNT', color: 'error', message: '', error },
        { root: true }
      )
      throw error
    }
  },
  async getScheduledSocialAccountPubliscations ({
    dispatch
  }: ActionContext<State, any>): Promise<
    ApiErrorHandler<{ [key: string]: ApiErrorHandler<any> }>
  > {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/scheduled',
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch(
        'app/addLogWithError',
        { title: 'GET_SCHEDULED_SOCIAL_ACCOUNT_PUBLICATIONS', color: 'error', message: '', error },
        { root: true }
      )
      throw error
    }
  },
  async loadScheduledSocialAccountPubliscations ({
    dispatch, state, commit
  }: ActionContext<State, any>) {
    const schedulePublications: ApiErrorHandler<{ [key: string]: ApiErrorHandler<any> }> = await dispatch('getScheduledSocialAccountPubliscations')
    if (hasApiError(schedulePublications)) {
      dispatch('app/notification', {
        content: 'No se pudieron obtener las publicaciones programadas',
        type: 'error',
        timeout: false
      }, { root: true })
      return
    }

    // @ts-ignore
    let currAccountSchedulePublications: ApiErrorHandler<any> = null
    const schedulePublicationEvents: BaseSchedulePublication[] = []
    const mapSocialAccouts = new Map(state.socialAccounts.map((account) => [account.id_account_social_media, account]))
    for (const id_account_social_media in schedulePublications.data) {
      currAccountSchedulePublications = schedulePublications.data[id_account_social_media]

      const socialAccount = mapSocialAccouts.get(Number(id_account_social_media))
      if (!socialAccount) continue

      const strategy = FactorySocialStrategy.getSocialStrategy(socialAccount.id_asm_type)
      if (!strategy) continue

      if (hasApiError(currAccountSchedulePublications)) {
        const socialMediaError = strategy.getApiError(currAccountSchedulePublications)

        dispatch('app/notification', {
          content: {
            component: PublishErrorNotification,
            props: {
              strategy,
              socialMediaError,
              responseApiError: currAccountSchedulePublications,
              additionalErrorText: 'No se pudieron obtener las publicaciones agendadas'
            },
            listeners: {
              copy: (data: any) => dispatch('app/copyMessage', { message: String(data) }, { root: true })
            }
          },
          type: 'error',
          timeout: false
        }, { root: true })
        continue
      }

      schedulePublicationEvents.push(...strategy.serializeSchedulePublicationData(currAccountSchedulePublications, strategy, socialAccount))
    }

    commit('SET_STATE', { schedulePublicationEvents })
  }
}
