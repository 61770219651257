/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ReportItemData, { ReportItemProperties } from './ReportItemData'
import { UnitConsole } from '@/store/interfaces/ReportModules'
import { PLATAFORM } from '@/store/resources/RESOURCES'

export default class RIEmpty
  extends ReportItemData<any, any>
  implements UnitConsole {
  public bateryFlag = 'V';
  public keyUnitMessage = ''
  public pType: PLATAFORM.EMPTY
  public currSpeed = 0

  constructor (unitConsole: UnitConsole, properties: ReportItemProperties<any>, options = { initObservers: true }) {
    super(unitConsole, properties, options)
    this.name_current = this.getName()
    this.pType = PLATAFORM.EMPTY
  }

  unsusbscribeToUnitMessages (): void {
    // --
  }

  susbscribeToUnitMessages (): void {
    // --
  }

  getName (): string {
    return this.unit_name
  }

  getIcon (): string {
    return '/images/truck.png'
  }

  async getIgnitionState (): Promise<number> {
    return -1
  }

  speed (): number {
    return 0
  }

  async getDistanceByTime (params: { from: number; to: number; }): Promise<number> {
    return 0
  }
}
