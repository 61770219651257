import { ActionContext } from 'vuex'
import { State } from './state'
import Ticket, { ITicket } from '@/classes/Ticket/ITicket'
import objToFormData from '@/utils/formdatacomment'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    dispatch('loadTicket')
  },
  async loadTicket ({ commit, dispatch }: ActionContext<State, any>, payload = { search: '', page: 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/ticket/get/all',
        method: 'GET',
        params: {
          ...payload
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, ticket: ITicket) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/ticket/create',
        method: 'POST',
        data: ticket
      },
      { root: true }
    )
    commit('PUSH_TICKET', data)
    return data
  },
  async createComment ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    console.log(formData)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/comment/${payload.ticketId}`,
        method: 'POST',
        data: formData
      },
      { root: true }
    )
    commit('UPDATE_TICKET', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, assignmentData: { ticketId: number, action: string, assignment_id: number }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/ticket/update/${assignmentData.ticketId}`,
          method: 'PUT',
          data: assignmentData
        },
        { root: true }
      )
      commit('UPDATE_TICKET', data)
      return data
    } catch (error) {
      await dispatch('app/notification', { content: 'No se ha podido dar seguimiento al Ticket', type: 'error' }, { root: true })
      throw error
    }
  },
  async reasigned ({ commit, dispatch }: ActionContext<State, any>, reassignData: { ticketId: number, payload: any }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/reasigned/${reassignData.ticketId}`,
        method: 'POST',
        data: reassignData.payload
      },
      { root: true }
    )
    commit('UPDATE_TICKET', data)
    return data
  },
  async loadTicketById ({ commit, dispatch }: ActionContext<State, any>, id: number) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/ticket/get/${id}`,
          method: 'GET'
        },
        { root: true }
      )
      commit('UPDATE_TICKET', data)
      return data
    } catch (error) {
      dispatch('app/addLogWithError', {
        title: 'Error al cargar los códigos QR',
        color: 'error',
        message: '',
        error
      }, { root: true })
      throw error
    }
  },
  async updateTicket ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/updateTicket/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_TICKET', data)
    return data
  },
  async updateComment ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/edit-comment/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async deleteComment ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/comment/delete/${payload.id}`,
        method: 'DELETE',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async userMentionToggle ({ dispatch }: ActionContext<State, any>, { id_ticket, id_user_profile }: { id_ticket: number, id_user_profile: number }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/mentioned_user/toggle/${id_ticket}/${id_user_profile}`,
        method: 'POST'
      },
      { root: true }
    )
    return data
  },
  async userMentionDelete ({ dispatch }: ActionContext<State, any>, { id_ticket, id_user_profile }: { id_ticket: number, id_user_profile: number }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/mentioned_user/delete/${id_ticket}/${id_user_profile}`,
        method: 'DELETE'
      },
      { root: true }
    )
    return data
  },
  async userMentionCreate ({ dispatch }: ActionContext<State, any>, { id_ticket, id_user_profile }: { id_ticket: number, id_user_profile: number }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/mentioned_user/delete/${id_ticket}/${id_user_profile}`,
        method: 'POST'
      },
      { root: true }
    )
    return data
  },
  async loadDashboard ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/ticket/dashboard',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async getPDF ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const response = await dispatch(
      'app/axios',
      {
        url: `/apis/ticket/pdf/${payload.id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      },
      { root: true }
    )
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${payload.folio}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)

    return response.data
  }
}
