/**
 * Converts an object to FormData.
 *
 * @param obj - The object to convert to FormData.
 * @param form - The existing FormData object to append the converted data to. If not provided, a new FormData object will be created.
 * @param namespace - The namespace to use for the form keys.
 * @param asArray - Indicates whether to treat the values as arrays.
 * @returns The FormData object with the converted data.
 */
export default function objToFormData (obj: any, form: FormData | null, namespace: string, asArray = false) {
  const formData = form || new FormData()
  let formKey

  for (const property in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]` + (asArray ? '[]' : '')
      } else {
        formKey = property
      }

      // Si el valor es un objeto y no un archivo,
      // llamada recursiva a la función para tratarlo como un objeto
      if (obj[property] instanceof Date) {
        // @ts-ignore
        formData.append(formKey, obj[property])
      } else if (typeof obj[property] === 'boolean' || obj[property] === 'true' || obj[property] === 'false') {
        formData.append(formKey, JSON.parse(String(obj[property])))
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objToFormData(obj[property], formData, formKey)
      } else if (Array.isArray(obj[property])) {
        for (let i = 0; i < obj[property].length; i++) {
          const element = obj[property][i]
          objToFormData(element, formData, formKey, true)
        }
      } else {
        // En caso contrario, añade la propiedad al objeto FormData
        formData.append(formKey, obj[property])
      }
    }
  }

  return formData
}
