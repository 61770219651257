export default function validateInstagramAspectRatio (
  file: File,
  {
    minAspectRatio,
    maxAspectRatio
  }: { minAspectRatio: number; maxAspectRatio: number } = {
    minAspectRatio: 0.8,
    maxAspectRatio: 1.91
  }
): Promise<{ width: number; height: number; ratio: number; valid: boolean }> {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      const width = img.naturalWidth
      const height = img.naturalHeight
      const ratio = width / height

      // Verificar si el ratio está en el rango permitido
      if (ratio >= minAspectRatio && ratio <= maxAspectRatio) {
        // Relación de aspecto válida
        resolve({ width, height, ratio, valid: true })
      } else {
        resolve({
          width,
          height,
          ratio,
          valid: false
        })
      }

      // Liberar el objeto URL creado
      URL.revokeObjectURL(img.src)
    }

    img.onerror = (error) => {
      reject(error)
    }

    // Crear un objeto URL a partir del File y asignarlo a la imagen
    img.src = URL.createObjectURL(file)
  })
}
