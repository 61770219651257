export enum PUBLICATION_TIME_TYPES {
  NOW = 'NOW',
  SCHEDULED = 'SCHEDULED',
}

export const PUBLICATION_TIME_TYPES_ARRAY = [
  {
    label: 'Publicar ahora',
    id: PUBLICATION_TIME_TYPES.NOW
  },
  {
    label: 'Programar publicación',
    id: PUBLICATION_TIME_TYPES.SCHEDULED
  }
]
