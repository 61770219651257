import { ApiErrorHandler } from '@/interfaces/ApiErrorHandler.interface'
import { SOCIAL_MEDIA_TYPE_LABELS, SOCIAL_MEDIA_TYPE, SOCIAL_MEDIA_TYPE_THUMBNAILS } from './../../catalogs/SOCIAL_MEDIA_TYPE'
import BaseSchedulePublication from '../SocialPublishScheduled/BaseSchedulePublication'
import { IAccountSocialMedia } from '@/store/interfaces/SocialAccounts.interface'

export interface IFileValidationError {
  message: string
  type: SOCIAL_MEDIA_TYPE
}

export interface IFileValidationRule {
  rule: boolean
  message: string
}

export interface ISocialMediaApiError {
  code: number
  name: string
  description: string
}

export default abstract class BaseSocialMediaStrategy {
  /**
     * Identificador de la red social que se va a utilizar para publicar
     */
    abstract type: SOCIAL_MEDIA_TYPE;

    /**
     * Tipos de archivos que se pueden publicar en la red social
     */
    abstract allowedMimeTypes: Set<string>;

    /**
     * Límite en KB del tamaño de las imágenes que se pueden publicar en la red social
     */
    abstract limitKBPostImageSize: number;

    /**
     * Límite en KB del tamaño de los videos que se pueden publicar en la red social
     */
    abstract limitKBPostVideoSize: number;

    abstract mainColor: string

    /**
     * Valida los archivos seleccionados para ver si se cumplen con los límites de la red social.
     * @param files Archivos seleccionados por el usuario para publicar
     * @returns Un array de errores de validación, el cual puede estar vacío si todos los archivos son válidos.
     */
    public async validateFiles (files: File[]): Promise<IFileValidationError[]> {
      const errors: IFileValidationError[] = []
      const errorsList = await this.getErrors(files)

      for (const error of errorsList) {
        if (error.rule) {
          errors.push({ message: error.message, type: this.type })
        }
      }

      return errors
    }

    /**
     * Obtiene una lista de reglas de validación de archivos que han fallado.
     * @param files Archivos seleccionados por el usuario para publicar
     * @returns Un array de reglas de validación de archivos, donde cada regla indica si se ha violado una condición.
     */
    abstract getErrors(files: File[]): Promise<IFileValidationRule[]>

    public hasFilesValidMimeTypes (files: File[]): boolean {
      return files.every(file => this.allowedMimeTypes.has(file.type))
    }

    public getInvalidFiles (files: File[]): File[] {
      return files.filter(file => !this.allowedMimeTypes.has(file.type))
    }

    public abstract getApiError(params: ApiErrorHandler): ISocialMediaApiError | undefined

    public get thumbnail () {
      return SOCIAL_MEDIA_TYPE_THUMBNAILS[this.type]
    }

    public get label () {
      return SOCIAL_MEDIA_TYPE_LABELS[this.type]
    }

    abstract showAssetsTextArea (files: File[]): boolean

    public abstract serializeSchedulePublicationData(data: ApiErrorHandler, strategy: BaseSocialMediaStrategy, account: IAccountSocialMedia): BaseSchedulePublication[]
}
