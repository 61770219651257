export interface ApiErrorHandler<DataType = any | null, TraceType = any> {
  error: number
  suberror: number | null
  message: string | null
  data: DataType
  trace: TraceType | null
}

export function hasApiError (error: ApiErrorHandler): boolean {
  return error?.error !== 0
}
