var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("tabs"), _c('div', {
    class: _vm.$slots.tabs ? 'wrapper_container_tabs' : 'wrapper_container'
  }, [_vm._t("default")], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }