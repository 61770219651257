import { SOCIAL_MEDIA_TYPE } from '@/store/catalogs/SOCIAL_MEDIA_TYPE'
import { FacebookSocialStrategy } from './FacebookSocialStrategy'
import { InstagramSocialStrategy } from './InstagramSocialStrategy'
import BaseSocialMediaStrategy from './BaseSocialMediaStrategy'

export default class FactorySocialStrategy {
  public static getSocialStrategy (type: SOCIAL_MEDIA_TYPE): BaseSocialMediaStrategy {
    switch (type) {
      case SOCIAL_MEDIA_TYPE.FACEBOOK:
        return new FacebookSocialStrategy()
      case SOCIAL_MEDIA_TYPE.INSTAGRAM:
        return new InstagramSocialStrategy()
      default:
        throw new Error('Social Media Type not supported')
    }
  }
}
