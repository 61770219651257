import { IAccountSocialMedia, IPublishingFileItem } from '@/store/interfaces/SocialAccounts.interface'
import BaseClass from '../BaseClass'
import BaseSocialMediaStrategy from '../SocialPublish/BaseSocialMediaStrategy'
import { SOCIAL_MEDIA_TYPE } from '@/store/catalogs/SOCIAL_MEDIA_TYPE'
import SchedulePublicationEvent from './SchedulePublicationEvent.interface'

export default abstract class BaseSchedulePublication extends BaseClass<SchedulePublicationEvent> implements SchedulePublicationEvent {
  strategy: BaseSocialMediaStrategy
  account: IAccountSocialMedia
  eventName!: string
  eventStartTimestamp!: number
  eventEndTimestamp!: number
  eventTimed!: boolean
  eventId!: string | number
  eventColor!: string
  eventDate!: string
  eventData!: any
  abstract publicationScheduledDate: string
  abstract publicationScheduledCreation: string

  constructor (params: { data: SchedulePublicationEvent, strategy: BaseSocialMediaStrategy; account: IAccountSocialMedia }) {
    super(params)
    this.strategy = params.strategy
    this.account = params.account
  }

  public get strategyType (): SOCIAL_MEDIA_TYPE {
    return this.strategy.type
  }

  public get strategylabel (): string {
    return this.strategy.label
  }

  public get strategyThumbnail () {
    return this.strategy.thumbnail
  }

  abstract get accountName(): string

  abstract getFiles(): Promise<IPublishingFileItem[]>

  abstract getDeletionData(): any
}
