<template>
  <div>
    <!-- Slot para las pestañas, mantenido de tu componente original -->
    <slot name="tabs"></slot>

    <!-- Overlay con transición de opacidad -->
    <transition name="fade">
      <div v-if="loading" class="loading-overlay">
        <div class="loading-spinner">
          <v-progress-circular
            :size="spinnerSize"
            :width="spinnerWidth"
            :color="spinnerColor"
            indeterminate
          ></v-progress-circular>
          <transition name="fade">
            <div v-if="loadingText" class="loading-text mt-2">
              {{ loadingText }}
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <!-- Contenedor principal con la clase original que tenías -->
    <div :class="[
      $slots.tabs ? 'wrapper_container_tabs' : 'wrapper_container',
      { 'content-loading': loading && fadeContent },
      { 'no-scroll': noScroll }
    ]">
      <!-- Transición para el contenido -->
      <transition name="fade-slide" mode="out-in">
        <div :key="loadingKey">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WrapContainer',
  props: {
    // Nuevas propiedades para el estado de carga
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: 'Cargando...'
    },
    spinnerSize: {
      type: Number,
      default: 50
    },
    spinnerWidth: {
      type: Number,
      default: 5
    },
    spinnerColor: {
      type: String,
      default: 'primary'
    },
    fadeContent: {
      type: Boolean,
      default: true
    },
    // Activar transición al cambiar contenido
    useContentTransition: {
      type: Boolean,
      default: false
    },
    // Nueva propiedad para desactivar el scroll
    noScroll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // Clave para forzar transición cuando cambia el contenido
    loadingKey () {
      return this.useContentTransition ? Date.now() : 'static'
    }
  }
}
</script>

<style lang="sass" scoped>
// Estilos originales
.wrapper_container
  overflow-y: auto
  position: relative
  transition: opacity 0.3s ease

.wrapper_container_tabs

  overflow-y: auto
  position: relative
  transition: opacity 0.3s ease

// Estilo para deshabilitar el scroll cuando noScroll es true
.no-scroll
  overflow: hidden !important

// La tabla dentro de un contenedor sin scroll debe tener su propio scroll

// Nuevos estilos para la funcionalidad de carga
.loading-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 999
  display: flex
  justify-content: center
  align-items: center

.loading-spinner
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 16px
  border-radius: 8px

.loading-text
  font-size: 14px
  color: rgba(0, 0, 0, 0.87)
  margin-top: 8px

.content-loading
  opacity: 0.3
  pointer-events: none

// Transiciones
.fade-enter-active, .fade-leave-active
  transition: opacity 0.3s ease

.fade-enter, .fade-leave-to
  opacity: 0

.fade-slide-enter-active, .fade-slide-leave-active
  transition: all 0.3s ease

.fade-slide-enter
  opacity: 0
  transform: translateY(10px)

.fade-slide-leave-to
  opacity: 0
  transform: translateY(-10px)
</style>
