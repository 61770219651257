import {
  IAccountSocialMedia,
  IAccountSocialMediaFacebookAndInstagramData,
  IPublishingFileItem
} from '@/store/interfaces/SocialAccounts.interface'
import BaseSchedulePublication from './BaseSchedulePublication'
import { PublicationSocialMedia } from '@/store/interfaces/PublicationSocialMedia.interface'
import store from '@/store'
import SchedulePublicationEvent from './SchedulePublicationEvent.interface'
import BaseSocialMediaStrategy from '../SocialPublish/BaseSocialMediaStrategy'

export default class InstagramSchedulePublication extends BaseSchedulePublication {
  publicationScheduledDate!: string;
  publicationScheduledCreation!: string;
  account!: IAccountSocialMedia<IAccountSocialMediaFacebookAndInstagramData>;
  eventData!: PublicationSocialMedia<
    {
      creation_id: string;
      message: string;
      media_type: string;
    },
    any
  >;

  constructor (params: {
    data: SchedulePublicationEvent<
      PublicationSocialMedia<
        {
          creation_id: string;
          message: string;
          media_type: string;
        },
        any
      >
    >;
    strategy: BaseSocialMediaStrategy;
    account: IAccountSocialMedia;
  }) {
    super(params)
    this.strategy = params.strategy
    this.account = params.account
    this.publicationScheduledDate = this.getNaturalFormatedDate({
      isoDate: params.data.eventData.psm_publication_date,
      // @ts-ignore
      offset: 0
    })
    this.publicationScheduledCreation = this.getNaturalFormatedDate({
      isoDate: params.data.eventData.psm_createdAt,
      // @ts-ignore
      offset: 0
    })
  }

  public onInitInstance (): void {
    // ---
  }

  get accountName (): string {
    return this.account.asm_data.name
  }

  async getFiles (): Promise<IPublishingFileItem[]> {
    // @ts-ignore
    const BASE_URL: string = store.state.app.BASE_URL
    const filesPromises = this.eventData.publication_files.map(
      async (fileItem) => {
        try {
          const url = `${BASE_URL}/${fileItem.fl_url}`
          const response = await fetch(url)
          const blob = await response.blob()
          const file = new File([blob], fileItem.fl_name, {
            type: fileItem.fl_mimetype
          })
          return {
            id: fileItem.id_file,
            file,
            caption: fileItem.fl_description
          } as IPublishingFileItem
        } catch (error) {
          console.error(error)
          return {
            id: fileItem.id_file,
            file: new File([], fileItem.fl_name),
            caption: fileItem.fl_description
          } as IPublishingFileItem
        }
      }
    )

    const files = await Promise.all(filesPromises)
    return files
  }

  getDeletionData () {
    return {
      id_publication_social_media: this.eventData.id_publication_social_media,
      id_account_social_media: this.account.id_account_social_media
    }
  }
}
