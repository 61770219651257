
export interface NoteCreditState {
  pagos: any[]
}

const state = (): NoteCreditState => ({
  pagos: []
})
export type State = ReturnType<typeof state>;

export default state
