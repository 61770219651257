import Vue from 'vue'
import { State } from './state'
export default {
  UPDATE_KEY (state:State) {
    state.key++
  },
  SET_UNITS_CONSOLE (state:State, units = []) {
    Vue.set(state, 'unitsRegisters', units)
  },
  PUSH_UNITS_CONSOLE (state:State, units = []) {
    Vue.set(state, 'unitsRegisters', [...state.unitsRegisters, ...units])
  },
  // PUSH_ITEM_TIRE (state: State, tire: Tire) {
  //   // const iw = { a: 'a' }
  //   const unitConsole = state.unitsRegisters.find(unit => unit.id === tire.unitconsole)
  //   const { tir_costo, ...rest } = tire
  //   unitConsole?.tires.push({ tir_costo: Number(tir_costo), ...rest })
  // },
  // UPDATE_ITEM_TIRE (state: State, tire: Tire) {
  //   // const iw = { a: 'a' }
  //   const { tir_costo, ...rest } = tire
  //   const unitConsole = state.unitsRegisters.find(unit => unit.id === tire.unitconsole)
  //   const tire_find = unitConsole?.tires.find(tire_find => tire_find.id === tire.id)
  //   // @ts-ignore
  //   Object.assign(tire_find, { tir_costo: Number(tir_costo), ...rest })
  //   // unitConsole?.tires.push({ tir_costo: Number(tir_costo), ...rest })
  // },
  SET_DRIVERS_CONSOLE (state:State, drivers = []) {
    state.driversRegisters = drivers
  },
  PUSH_DRIVER_CONSOLE (state:State, driver:any) {
    state.driversRegisters.push(driver)
  },
  UPDATE_DRIVER_CONSOLE (state:State, driver:any) {
    const driverUpdate = state.driversRegisters.find(driverR => driverR.id === driver.id)
    // @ts-ignore
    Object.assign(driverUpdate, { ...driver })
  }
}
