/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { SocketEvents } from '@/interfaces/SocketEvents.interface'
import { serializeError } from 'serialize-error'
import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM
} from '../resources/RESOURCES'
import { State } from './state'
import datetimeToDate, { getCurrentDate, getDateLocal } from '@/utils/datetimeToDate'
import RIWialon from '../packages/ReportItem/RIWialon'
import { ESTATUS_TRAVEL } from '../catalogs/ESTATUS_TRAVEL'
import actions from '@/store/travel/actions/index'
import { NOTIFICATION_CONSOLE } from '../catalogs/NOTIFICATION_CONSOLE'
import actionsLoadfuel from '../travel/modules/loadfuel/actions'
import { ET_HIKVISION, EVENTS_TYPES } from '../catalogs/EVENTS_TYPES'
import Notification from '@/classes/Notification/Notification'
import toPascalCase from '@/utils/toPascalCase'
import { trycatchAsync, trycatchSync } from '@/utils/trycatch'
import UnitConsoleData from '@/store/packages/ReportItem/ReportItemData'
import RIHikvision from '../packages/ReportItem/RIHikvision'
import { notifyToast } from '@/utils/notifications'

export default {
  async init ({ commit, dispatch, rootGetters, rootState }: ActionContext<State, any>) {
    try {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: true,
          message: 'Descargando Recursos'
        },
        { root: true }
      )

      // Llamadas a `dispatch` fuera del `if` para ejecutarlas siempre
      trycatchAsync(() => dispatch('loadCatalogs'), null, { logError: true })
      trycatchAsync(() => dispatch('loadColors'), null, { logError: true })
      trycatchAsync(() => dispatch('loadTravelTypes'), null, { logError: true })
      trycatchAsync(() => dispatch('loadFinishedTravels'), null, { logError: true })
      trycatchAsync(() => dispatch('loadFinishTravels'), null, { logError: true })
      trycatchAsync(() => dispatch('loadDrivers'), null, { logError: true })

      await trycatchAsync(() => dispatch('loadUnitsConsole'), null, { logError: true })
      await trycatchAsync(() => dispatch('loadTravels'), null, { logError: true })
      await trycatchAsync(() => dispatch('getWialonResourcesIdsAndUnits'), null, { logError: true })
      await trycatchAsync(() => dispatch('subscribeNotifications'), null, { logError: true })
      await trycatchAsync(() => dispatch('mapon/initNotifications', true, { root: true }), null, { logError: true })
    } catch (error) {
      console.error(error)
      commit(
        'app/ADD_LOG',
        {
          title: 'OBTENIENDO RECURSOS',
          color: 'error',
          message: error
        },
        { root: true }
      )
    } finally {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: false,
          message: 'Descargando Recursos'
        },
        { root: true }
      )
    }
  },
  async loadCatalogs ({ dispatch }: ActionContext<State, any>) {
    await trycatchAsync(() => dispatch('loadResources'), null, { logError: true })
    await trycatchAsync(() => dispatch('loadUnits'), null, { logError: true })
  },
  async events ({ rootState, dispatch, commit, rootGetters }: ActionContext<State, any>, event: SocketEvents) {
    try {
      switch (event.event) {
        case 'destination_leave': {
          // @ts-ignore
          const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.travel_id === event.message.id_travel)
          travel.tra_estado_travel = ESTATUS_TRAVEL.EN_VIAJE
          travel.estatus_download = false
          travel.in_travel = true
          // @ts-ignore
          const destination = travel.tvl_destinations.find(des => des.pk === event.message.id_destination)
          // @ts-ignore
          const unit_name = travel.tra_plataform_unit.name
          // @ts-ignore
          const unit:RIWialon = rootState.travel.unitsRegisters.find(unitR => parseInt(unitR.unit_resource_id) === travel.tra_plataform_unit.id)
          await dispatch('wialon/addNotificationConsole',
            {
              name: 'Sale de origen',
              txt: `${unit_name} sale de ${destination.tvd_plataform_route_origin.name}. En ${datetimeToDate(new Date().toString())}`,
              color: '#f6d501',
              unit: travel.tra_plataform_unit.id,
              coord: { x: unit.last_message.pos.x, y: unit.last_message.pos.y },
              nid: NOTIFICATION_CONSOLE.DESTINATION_LEAVE,
              pType: travel.tra_plataform
            },
            { root: true })
          notifyToast({
            msj: `Unidad ${unit_name} ha salido de ${destination.tvd_plataform_route_origin.name} `,
            type: 'success',
            title: 'Unidad Sale de Origen',
            icon: 'mdi-truck-delivery-outline'
          })
          dispatch('app/playSound', { type: 'done' }, { root: true })
          break
        }
        case 'destination_arrival': {
          // @ts-ignore
          const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.travel_id === event.message.id_travel)
          travel.tra_estado_travel = ESTATUS_TRAVEL.DESCARGANDO_EN
          travel.estatus_download = true
          travel.estatus_download_geofence = event.message.route_name
          travel.tra_destination_current = event.message.id_destination
          // @ts-ignore
          const destination = travel.tvl_destinations.find(des => des.pk === event.message.id_destination)
          // @ts-ignore
          const unit_name = travel.tra_plataform_unit.name
          // @ts-ignore
          const unit:RIWialon = rootState.travel.unitsRegisters.find(unitR => parseInt(unitR.unit_resource_id) === travel.tra_plataform_unit.id)
          await dispatch('wialon/addNotificationConsole',
            {
              name: 'Llego a su destino',
              txt: `${unit_name} llego a su destino en ${destination.tvd_plataform_route_origin.name}. En ${datetimeToDate(new Date().toString())}`,
              color: '#009302',
              unit: travel.tra_plataform_unit.id,
              coord: { x: unit.last_message.pos.x, y: unit.last_message.pos.y },
              nid: NOTIFICATION_CONSOLE.DESTINATION_ARRIVAL,
              pType: travel.tra_plataform
            },
            { root: true })
          notifyToast({
            msj: `Unidad ${unit_name} llego ha ${destination.tvd_plataform_route_destination.name} `,
            type: 'success',
            title: 'Unidad en Destino',
            icon: 'mdi-map-marker'
          })
          dispatch('app/playSound', { type: 'done' }, { root: true })
          break
        }
        case 'change_locks': {
          // @ts-ignore
          const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.travel_id === event.message.tra_id)
          // @ts-ignore
          // const destination = travel.tvl_destinations.find(des => des.pk === event.message.id_destination)
          // @ts-ignore
          // const unit_name = travel.tra_plataform_unit.name
          await dispatch('updateLocks', event.message)
          notifyToast({
            msj: `Se han cambiado los candados del viaje "${travel.tra_name}"`,
            type: 'success',
            title: 'Notificación de operación',
            icon: 'mdi-lock-open'
          })
          dispatch('app/playSound', { type: 'done' }, { root: true })
          break
        }
        case 'destination_origin': {
          // @ts-ignore
          const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.travel_id === event.message.id_travel)
          travel.tra_estado_travel = ESTATUS_TRAVEL.CARGANDO_EN
          if (!travel.tra_destination_current === event.message.id_destination) return
          // @ts-ignore
          const destination = travel.tvl_destinations.find(des => des.pk === event.message.id_destination)
          destination.tvd_in_origin_geofence = true
          // @ts-ignore
          const unit_name = travel.tra_plataform_unit.name
          // @ts-ignore
          const unit:RIWialon = rootState.travel.unitsRegisters.find(unitR => parseInt(unitR.unit_resource_id) === travel.tra_plataform_unit.id)
          await dispatch('wialon/addNotificationConsole',
            {
              name: 'Unidad se encuentra sobre su origen',
              txt: `${unit_name} se encuentra sobre ${destination.tvd_plataform_route_origin.name}. En ${datetimeToDate(new Date().toString())}`,
              color: '#0db0f2',
              unit: travel.tra_plataform_unit.id,
              coord: { x: unit.last_message.pos.x, y: unit.last_message.pos.y },
              nid: NOTIFICATION_CONSOLE.DESTINATION_ORIGIN,
              pType: travel.tra_plataform
            },
            { root: true })
          notifyToast({
            msj: `Viaje ${travel.tra_name.toUpperCase()} se encuentra sobre ${destination.tvd_plataform_route_origin.name}`,
            type: 'info',
            title: 'Unidad en Origen',
            icon: 'mdi-map-marker'
          })
          dispatch('app/playSound', { type: 'done' }, { root: true })
          break
        }
        case 'destination_out': {
          // @ts-ignore
          const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.travel_id === event.message.id_travel)
          travel.estatus_download = false
          travel.tra_estado_travel = ESTATUS_TRAVEL.EN_VIAJE
          // @ts-ignore
          const destination = travel.tvl_destinations.find(des => des.pk === event.message.id_destination)
          // @ts-ignore
          const unit_name = travel.tra_plataform_unit.name
          // @ts-ignore
          const unit:RIWialon = rootState.travel.unitsRegisters.find(unitR => parseInt(unitR.unit_resource_id) === travel.tra_plataform_unit.id)
          await dispatch('wialon/addNotificationConsole',
            {
              name: 'Unidad ha salido de su destino',
              txt: `${unit_name} sale de ${destination.tvd_plataform_route_origin.name}. En ${getCurrentDate()}`,
              color: '#f6d501',
              unit: travel.tra_plataform_unit.id,
              coord: { x: unit.last_message.pos.x, y: unit.last_message.pos.y },
              nid: NOTIFICATION_CONSOLE.DESTINATION_OUT,
              pType: travel.tra_plataform
            },
            { root: true })
          notifyToast({
            msj: `Viaje ${travel.tra_name.toUpperCase()} ha salido de ${destination.tvd_plataform_route_destination.name}`,
            type: 'info',
            title: 'Unidad Sale de Destino',
            icon: 'mdi-map-marker'
          })
          dispatch('app/playSound', { type: 'done' }, { root: true })
          break
        }
        case 'travel_finalized': {
          // @ts-ignore
          const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.travel_id === event.message.id_travel)
          if (travel) {
            await dispatch('finishTravel', travel)
          }
          break
        }
        case 'checklist_filled': {
          // @ts-ignore
          const travel = rootState.resources[RESOURCES.TRAVELS].find(tvl => tvl.travel_id === event.message.travel_id)
          travel.checklist = event.message.checklist
          travel.checklist_is_filled = true
          notifyToast({
            msj: `Checklist del viaje ${travel.tra_name} ha sido respondido`,
            type: 'success',
            title: 'Checklist respondido',
            icon: 'mdi-check-circle'
          })
          dispatch('app/playSound', { type: 'notification' }, { root: true })
          break
        }
        case 'notification_camera': {
          switch (event.message.event_type) {
            case EVENTS_TYPES.HIKVISION_DG_EVENTS:
              if (event.message?.data?.EventNotificationAlert?.eventType === ET_HIKVISION.GPSUpload.type) {
                const deviceID = event.message?.data?.EventNotificationAlert.deviceID
                const units: UnitConsoleData<unknown, unknown>[] = rootState.travel.unitsRegisters
                const index = units.findIndex(unit => unit.pType === PLATAFORM.HIKVISION && unit.haveHVEHomeIDCamera(deviceID))
                const GPSdata = event.message?.data?.EventNotificationAlert?.GPS
                if (index === -1) return
                (rootState.travel.unitsRegisters[index] as RIHikvision).setUnitData({
                  last_message: {
                    pos: {
                      x: parseInt(GPSdata.longitude) / (3600 * 100) * (GPSdata.divisionEW === 'E' ? 1 : -1),
                      y: parseInt(GPSdata.latitude) / (3600 * 100) * (GPSdata.divisionNS === 'N' ? 1 : -1),
                      c: parseInt(GPSdata.direction) / 100
                    }
                  },
                  currSpeed: GPSdata.speed / 100000
                })
                return
              }
              if (!rootState.cameras.hvDisableNotifications) return

              const isNotEnabled:boolean = rootState.cameras.notifications_active[event?.message?.data?.EventNotificationAlert?.eventType as string]
              if (!isNotEnabled) return

              const units:UnitConsoleData<unknown, unknown>[] = rootGetters['travel/getUnitByDeviceID'](event.message.event_key)
              if (!units) return
              // @ts-ignore
              const NOTIFICATION = ET_HIKVISION[event.message.data.EventNotificationAlert.eventType as string]
              for (let index = 0; index < units.length; index++) {
                const unit = units[index]

                const cam = unit.cameras && unit.cameras.cpt_properties.EhomeID === event.message.event_key
                  ? unit.cameras
                  : null

                const eventType = trycatchSync(() => toPascalCase(event.message?.data?.EventNotificationAlert?.eventType), '')
                const notMessData = trycatchSync(() => event.message?.data?.EventNotificationAlert[eventType].DevicePositionInfo, null)
                const coordinates = notMessData === null
                  ? {
                      latitude: 0,
                      longitude: 0
                    }
                  : {
                      latitude: parseInt(notMessData.latitude) / (3600 * 100) * (notMessData.divisionNS === 'N' ? 1 : -1),
                      longitude: parseInt(notMessData.longitude) / (3600 * 100) * (notMessData.divisionEW === 'E' ? 1 : -1)
                    }
                const notify = {
                  id_unit: unit.id,
                  unit_name: unit.name_current,
                  cam_name: cam?.cpf_name,
                  cam_id: event.message.event_key,
                  notification: NOTIFICATION.name,
                  message: NOTIFICATION.getNotName({ event: event.message }),
                  txt: NOTIFICATION.getNotMessage({ event: event.message, report_item: unit }),
                  date: getDateLocal(event.message.data.EventNotificationAlert?.dateTime),
                  icon: NOTIFICATION.icon,
                  color: NOTIFICATION.color,
                  urlMaps: (coordinates.latitude === 0 && coordinates.longitude === 0) ? null : `http://maps.google.com/?q=${coordinates.latitude},${coordinates.longitude}`,
                  resources: await dispatch('cameras/getHikResources', { objname: toPascalCase(event.message?.data?.EventNotificationAlert?.eventType), event, unit }, { root: true })
                }
                for (let index = 0; index < rootState.cameras.notifications.length; index++) {
                  const element:Notification = rootState.cameras.notifications[index]
                  const isSameUnit = element.id_unit === notify.id_unit
                  const isSameTypeNotify = element.notification === notify.notification
                  const isSameCamID = element.cam_id === notify.cam_id
                  if (isSameTypeNotify && isSameUnit && isSameCamID) {
                    element.notification_child?.unshift(new Notification(notify))
                    if (!rootState.cameras.hvDisableSoundNotifications) return
                    dispatch('app/playSound', { type: 'camera_alert' }, { root: true })
                    return
                  }
                }
                rootState.cameras.notifications.unshift(new Notification(notify))
                if (!rootState.cameras.hvDisableSoundNotifications) return
                dispatch('app/playSound', { type: 'camera_alert' }, { root: true })
              }
              break
            default:
              break
          }
        }
      }
    } catch (error) {
      console.log(error)
      commit('ADD_LOG', { title: 'EVENT_TRAVEL_ERROR', color: 'error', message: error, payload: serializeError(error) }, { root: true })
    }
  },
  async subscribeNotifications ({ rootState, dispatch }: ActionContext<State, any>) {
    const currentResIds: number[] = rootState.resources[RESOURCES.TRAVEL_RESOURCES_IDS]
    for (let i = 0; i < currentResIds.length; i++) {
      const resId = currentResIds[i]
      await dispatch('wialon/suscribeNotificationResource', resId, { root: true })
    }
  },
  async addNewResourceNotificationUnit ({ dispatch, rootState, commit }: ActionContext<State, any>, data: any) {
    const currentResIds: number[] = rootState.resources[RESOURCES.TRAVEL_RESOURCES_IDS]
    const currentUnitsIds: number[] = rootState.resources[RESOURCES.TRAVEL_RESOURCES_IDS]

    const unit_id = data[0].tra_plataform_unit.plataform_unit_id
    const res_id = data[0].transport_line_resource_id
    if (!currentResIds.includes(res_id)) {
      await dispatch('wialon/suscribeNotificationResource', res_id, { root: true })
      commit(
        'resources/PUSH_RESOURCE',
        {
          item: res_id,
          rType: RESOURCES.TRAVEL_RESOURCES_IDS
        },
        { root: true }
      )
    }
    if (!currentUnitsIds.includes(unit_id)) {
      commit(
        'resources/PUSH_RESOURCE',
        {
          item: unit_id,
          rType: RESOURCES.TRAVEL_UNITS_IDS
        },
        { root: true }
      )
    }
  },
  ...actions,
  ...actionsLoadfuel
}
