import { ActionContext } from 'vuex'
import { State } from './state'
import { DateTime } from 'luxon'
const URL_ACTION = '/apis/pago/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<State, any>, { search = '', page = 1 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET',
        params: {
          search, // Parámetro de búsqueda
          page // Parámetro de paginación
        }
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getById ({ commit, dispatch }: ActionContext<State, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    // payload.timezone = Vue.prototype.$getTimezoneOffsetHours()
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
    // const celeryResult = await dispatch('app/checkCeleryTaskStatus', {
    //   id: data.task_id,
    //   returnResult: true,
    //   errorResponseType: 'view_error'
    // }, { root: true })

    // return celeryResult.result
  },
  async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE', data)
    return data
  },
  async delete ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  },
  async pagosByFactura ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}list/${payload.entityType}/${payload.id}`,
        method: 'GET'
        // data: payload
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  },
  async timbrarPago ({ dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const isDev = rootState.app.IS_DEV
    try {
      const response = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}timbrar_xml`,
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      if (isDev) console.log('Respuesta timbrar_xml:', response)

      const timbraXMLCeleryResult = await dispatch('app/checkCeleryTaskStatus', {
        id: response.data.task_id,
        returnResult: true,
        sucessResponseType: 'raw',
        errorResponseType: 'view_error',
        sucessRequestConfig: {
          responseType: 'blob'
        }
      }, { root: true })

      const blob = new Blob([timbraXMLCeleryResult], { type: 'application/xml' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${payload.folio}.xml`) // Nombre del archivo
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      return response.data // Si necesitas devolver algo a la función que llama
    } catch (error) {
      console.error('Error timbrar_xml:', error)
      // @ts-ignore
      if (error?.response?.data) {
      // @ts-ignore
        throw error.response.data // Relanza el error para ser manejado por la lógica del componente
      } else {
        throw error
      }
    }
  },
  async getPDF ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const now = DateTime.local()
    const timezoneOffsetHours = now.offset / 60
    const response = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}pdf/${payload.id}?timezone=${timezoneOffsetHours}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      },
      { root: true }
    )

    const blob = new Blob([response.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${payload.folio}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)

    return response.data
  },
  async sendEmail ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}sendemail/${payload.id}`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async cancelFactura ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    try {
      const response = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}cancelar`,
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      const timbraXMLCeleryResult = await dispatch('app/checkCeleryTaskStatus', {
        id: response.data.task_id,
        returnResult: true,
        sucessResponseType: 'raw',
        errorResponseType: 'view_error',
        sucessRequestConfig: {
          responseType: 'blob'
        }
      }, { root: true })

      const blob = new Blob([timbraXMLCeleryResult], { type: 'application/xml' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Acuse_${payload.folio}.xml`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      window.URL.revokeObjectURL(url)
      return timbraXMLCeleryResult
    } catch (error) {
      console.error('Error timbrar_xml:', error)
      // @ts-ignore
      if (error?.response?.data) {
        // @ts-ignore
        throw error.response.data // Relanza el error para ser manejado por la lógica del componente
      } else {
        throw error
      }
    }
  },
  async getAcuse ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const response = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}acuse/${payload.id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/xml'
        },
        responseType: 'blob'
      },
      { root: true }
    )

    const blob = new Blob([response.data], { type: 'application/xml' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Acuse_${payload.folio}.xml`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)

    return response.data
  },
  async getPDFAcuse ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const now = DateTime.local()
    const timezoneOffsetHours = now.offset / 60
    const response = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}acuse_pdf/${payload.id}?timezone=${timezoneOffsetHours}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      },
      { root: true }
    )

    const blob = new Blob([response.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Acuse_${payload.folio}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)

    return response.data
  },
  async upload_pago ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const response = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}upload_pago`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return response.data
  }
}
export default actions
