import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
// import { productos } from './productos'
// import { clientes } from './clientes'
import { system_update } from './modules/updates'

export const system_updates: Module<any, RootState> = {
  namespaced: true,
  modules: {
    system_update
  }
}
