<template>
  <v-dialog v-model="show" v-if="show" max-width="700px" persistent class="system-updates-modal">
    <v-card>
      <v-toolbar flat dense color="primary" dark>
        <v-toolbar-title class="font-weight-medium">Novedades del Sistema</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-4 pb-6">
        <div v-if="loading" class="text-center pa-4">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <div class="mt-2">Cargando actualización...</div>
        </div>

        <div v-else-if="!update" class="text-center pa-4">
          <v-icon color="grey lighten-1" large>mdi-information-outline</v-icon>
          <div class="caption grey--text mt-1">No se encontró la actualización</div>
        </div>

        <div v-else>
          <div class="d-flex mb-3 align-center">
            <v-avatar class="mr-3 elevation-1" :color="getUpdateTypeColor(update.update_type)" size="48">
              <v-icon dark>{{ getUpdateTypeIcon(update.update_type) }}</v-icon>
            </v-avatar>

            <div>
              <div class="d-flex align-center">
                <v-chip small :color="getImportanceColor(update.importance)" label text-color="white" class="mr-2">
                  {{ getImportanceLabel(update.importance) }}
                </v-chip>
                <h3 class="text-h6 font-weight-bold mb-0">{{ update.title }}</h3>
              </div>
              <div class="d-flex align-center mt-1">
                <v-chip x-small outlined label class="mr-2">v{{ update.version }}</v-chip>
                <span class="caption grey--text">{{ formatDate(update.release_date) }}</span>
              </div>
            </div>
          </div>

          <v-divider></v-divider>

          <div class="mt-4 update-description" v-html="formatDescription(update.description)"></div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { parseISO, format } from 'date-fns'
import { es } from 'date-fns/locale'

export default {
  name: 'SystemUpdatesModal',

  data () {
    return {
      show: false,
      loading: false,
      update: null
    }
  },

  async mounted () {
    window.addEventListener('show-system-updates-modal', this.openModal)
  },
  beforeDestroy () {
    window.removeEventListener('show-system-updates-modal', this.openModal)
  },
  methods: {
    async openModal (event) {
      this.show = true
      const updateId = event.detail?.updateId
      this.loadUpdate(updateId)
    },

    closeModal () {
      this.show = false
      this.update = null
    },

    async loadUpdate (id) {
      this.loading = true
      try {
        const response = await this.$store.dispatch('system_updates/system_update/getById', id)
        this.update = response
      } catch (error) {
        console.error(error)
        this.update = null
      } finally {
        this.loading = false
      }
    },

    formatDate (dateString) {
      const parsedDate = parseISO(dateString)
      return format(parsedDate, 'PPP', { locale: es })
    },

    formatDescription (description) {
      if (!description) return ''

      return description
        .replace(/\n/g, '<br>')
        .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>')
    },

    getUpdateTypeColor (type) {
      const colors = {
        1: 'green',
        2: 'blue',
        3: 'orange',
        4: 'grey'
      }
      return colors[type] || 'grey'
    },

    getUpdateTypeIcon (type) {
      const icons = {
        1: 'mdi-star-plus-outline',
        2: 'mdi-arrow-up-bold',
        3: 'mdi-bug-check-outline',
        4: 'mdi-information-outline'
      }
      return icons[type] || 'mdi-information-outline'
    },

    getImportanceLabel (importance) {
      const labels = {
        1: 'Crítica',
        2: 'Mayor',
        3: 'Menor'
      }
      return labels[importance] || 'Desconocida'
    },

    getImportanceColor (importance) {
      const colors = {
        1: 'red',
        2: 'orange',
        3: 'blue'
      }
      return colors[importance] || 'grey'
    }
  }
}
</script>

<style scoped>
.update-description {
  white-space: pre-line;
  font-size: 14px;
  line-height: 1.6;
  max-height: 400px;
  overflow-y: auto;
  padding: 8px 16px;
}

.update-description>>>a {
  text-decoration: none;
  color: #1976d2;
}

.update-description>>>a:hover {
  text-decoration: underline;
}

.update-description>>>ul {
  padding-left: 16px;
}

.update-description>>>li {
  margin-bottom: 8px;
}
</style>
