import { PUBLICATION_TIME_TYPES } from '../catalogs/PUBLICATION_TIME_TYPES'
import { IAccountSocialMedia, IPublishingFileItem } from '../interfaces/SocialAccounts.interface'
import StrategyAccountSocialMediaManager from '../packages/SocialPublish/StrategyAccountSocialMediaManager'
import BaseSchedulePublication from '../packages/SocialPublishScheduled/BaseSchedulePublication'

const state = () => ({
  socialAccounts: [] as IAccountSocialMedia[],
  schedulePublicationEvents: [] as BaseSchedulePublication[],
  publishingPreviewData: {
    loading: false,
    selectedAccounts: [] as IAccountSocialMedia[],
    strategyAccountSocialMediaManager: new StrategyAccountSocialMediaManager(),
    form: {
      message: '',
      publication_time_type: PUBLICATION_TIME_TYPES.NOW,
      files: [] as IPublishingFileItem[],
      publication_time: null as null | Date
    }
  }
})

export type State = ReturnType<typeof state>;

export default state
