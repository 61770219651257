/* eslint-disable @typescript-eslint/ban-types */
import { ActionContext } from 'vuex'
import axios, { AxiosRequestConfig } from 'axios'
import { ToastID, ToastOptions } from 'vue-toastification/dist/types/src/types'
import { State } from './state'
import { IMessageEvent, w3cwebsocket as WebSocketClient } from 'websocket'
import { serializeError } from 'serialize-error'
import '@mdi/font/css/materialdesignicons.min.css'
import { SocketEvents } from '@/interfaces/SocketEvents.interface'
import { notifyToast } from '@/utils/notifications'
import Vue from 'vue'
import { ICeleryTaskResult } from '@/interfaces/Celery.interface'
import ExcelJS from 'exceljs'
import { fillSheetTemplate, IGetTemplateVariables, ITemplateTableShape } from '@/utils/generateExcelTemplate'
import getExcelJSTableBounds from '@/utils/getExcelJSTableBounds'
import getColumnPosition from '@/utils/getExcelJSColumnPosition'
import { IExcelTemplateData } from '../interfaces/IExcelTemplateData.interface'
import getUrlOrigin from '@/utils/getUrlOrigin'

export default {
  init ({ commit, dispatch }: ActionContext<State, any>) {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
      commit('ADD_LOG', {
        title: 'APP_ERROR',
        color: 'error',
        message: msg,
        payload: JSON.stringify({ url, lineNo, columnNo, error })
      })
      return false
    }
    dispatch('socketAccount/initSocketAccount')
  },
  initSocket (
    { commit, rootGetters, rootState, dispatch }: ActionContext<State, any>,
    callback: (message: IMessageEvent) => void
  ) {
    const isHttps = window.location.protocol === 'https:'
    const protocol = isHttps ? 'wss:' : 'ws:'
    const urlConection = rootGetters['auth/isMonitorist']
      ? `${protocol}//${process.env.VUE_APP_HOST || window.location.host
      }/socket/travel/monitorista/`
      : `${protocol}//${process.env.VUE_APP_HOST || window.location.host
      }/socket/travel/${rootState.auth.user_date.id_user}/`
    commit('CLEAR_SOCKET_TRAVEL')
    if (urlConection) {
      const client = new WebSocketClient(urlConection)

      client.onopen = function () {
        console.log('WebSocket Client Connected')
      }

      client.onerror = function (error) {
        commit('ADD_LOG', {
          title: 'SOCKET_ERROR',
          color: 'error',
          message: error,
          payload: serializeError(error)
        })
        console.log(error)
      }

      client.onclose = async function (event) {
        commit('ADD_LOG', {
          title: 'SOCKET_CLOSED',
          color: 'info',
          message: event
        })
        await dispatch('retryConection')
      }

      client.onmessage = callback

      commit('SET_SOCKET_TRAVEL', client)
    }
  },
  initSocketByUser (
    { commit, rootGetters, rootState, dispatch }: ActionContext<State, any>,
    callback: (message: IMessageEvent) => void
  ) {
    const isHttps = window.location.protocol === 'https:'
    const protocol = isHttps ? 'wss:' : 'ws:'
    const urlConection = rootGetters['auth/isMonitorist']
      ? `${protocol}//${process.env.VUE_APP_HOST || window.location.host
      }/socket/travel/monitorista/`
      : `${protocol}//${process.env.VUE_APP_HOST || window.location.host
      }/socket/travel/${rootState.auth.user_date.id}/`
    commit('CLEAR_SOCKET_SYSTEM')

    if (urlConection) {
      const client = new WebSocketClient(urlConection)

      client.onopen = function () {
        console.log('WebSocket Client Connected')
      }

      client.onerror = function (error) {
        commit('ADD_LOG', {
          title: 'SOCKET_ERROR',
          color: 'error',
          message: error,
          payload: serializeError(error)
        })
        console.log(error)
      }

      client.onclose = async function (event) {
        commit('ADD_LOG', {
          title: 'SOCKET_CLOSED',
          color: 'info',
          message: event
        })
        await dispatch('retryConectionSystem')
      }

      client.onmessage = callback

      commit('SET_SOCKET_SYSTEM', client)
    }
  },
  sendMessageThroughSocket (
    { rootState }: ActionContext<State, any>) {
    if (rootState.app.socket_system) {
      try {
        console.log('enviando data')
        // rootState.app.socket_system.send(JSON.stringify(message))
        // commit('ADD_LOG', { title: 'SOCKET_MESSAGE_SENT', color: 'success', message: 'Message sent successfully' })
      } catch (error) {
        // commit('ADD_LOG', { title: 'SOCKET_MESSAGE_ERROR', color: 'error', message: error, payload: serializeError(error) })
      }
    } else {
      // commit('ADD_LOG', { title: 'SOCKET_NOT_CONNECTED', color: 'error', message: 'WebSocket is not connected' })
    }
  },
  async socketEvent (
    { rootState, dispatch }: ActionContext<State, any>,
    event: SocketEvents
  ) {
    console.log(event)
    switch (event.event) {
      case 'ticket':
        rootState.app.notifications.notifications.unshift(event.message)
        if ('Notification' in window && Notification.permission === 'granted') {
          const notification = new Notification('Notificación Ananta ERP', {
            body: event.message.notification.message,
            icon: 'https://cdn-icons-png.flaticon.com/512/846/846449.png'
          })
        } else if (Notification.permission !== 'denied') {
          const permiso = await Notification.requestPermission()
          if (permiso === 'granted') {
            const notification = new Notification(' Notificación Ananta ERP', {
              body: event.message.notification.message,
              icon: 'https://cdn-icons-png.flaticon.com/512/846/846449.png'
            })
          }
        }
        notifyToast({
          msj: event.message.notification.message,
          type: 'info',
          title: event.message.notification.title,
          icon: 'mdi-message-text'
        })

        await dispatch('playSound', { type: 'notify1' })
        break

      case 'alert':
        notifyToast({
          msj: event.message.message,
          type: 'warning',
          title: event.message.title,
          icon: 'mdi-update',
          duration: 20000
        })
        await dispatch('playSound', { type: 'error' })
        break
      case 'unit_assigned': {
        notifyToast({
          msj: `Conductor ${event.message.driver_data.username} asignado a la unidad ${event.message.unit_name}`,
          type: 'success',
          title: 'Conductor Actualizado',
          icon: 'mdi-card-account-details-outline',
          duration: 20000
        })
        // @ts-ignore
        const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find(obj => obj.id === event.message.unit)
        // @ts-ignore
        const previousUnitWithDriver = rootState.travel.unitsRegisters.find(obj => obj.driver_profile?.id === event.message.driver_data.id)
        if (unitConsoleData) {
          if (unitConsoleData.driver_profile) {
            notifyToast({
              msj: `Conductor ${unitConsoleData.driver_profile.username} desvinculado de la unidad ${unitConsoleData.unit_name}`,
              type: 'warning',
              title: 'Conductor Reemplazado',
              icon: 'mdi-car-switch',
              duration: 20000

            })
          }
          if (previousUnitWithDriver) {
            notifyToast({
              msj: `Conductor ${previousUnitWithDriver.driver_profile.username} desvinculado de la unidad ${previousUnitWithDriver.unit_name}`,
              type: 'info',
              title: 'Conductor Desvinculado',
              icon: 'mdi-card-account-details-outline',
              duration: 20000
            })
            previousUnitWithDriver.driver_profile = null
          }
          unitConsoleData.setDriver(event.message.driver_data)
        }
        await dispatch('playSound', { type: 'zumbido' })
        break
      }
      default:
    }
  },
  async initNotifyFirebase (_: ActionContext<State, any>) {
    try {
      // const token = await fetchToken()
      // console.log('------------')
      // console.log('------------')
      // console.log(token)
      // console.log('------------')
      // console.log('------------')
      // await dispatch('auth/setToken', token, { root: true })
      // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      // onMessage(messaging, (payload) => {
      //   console.log('Message received. ', payload)
      // })
    } catch (error) {
      console.error(error)
    }
  },
  async retryConection ({ dispatch }: ActionContext<State, any>) {
    setTimeout(async () => {
      await dispatch(
        'app/initSocket',
        (message: IMessageEvent) => {
          const data = JSON.parse(message.data.toString())
          dispatch('travel/events', data, { root: true })
        },
        { root: true }
      )
    }, 5000)
  },
  async retryConectionSystem ({ dispatch }: ActionContext<State, any>) {
    setTimeout(async () => {
      await dispatch(
        'app/initSocketByUser',
        (message: IMessageEvent) => {
          const data = JSON.parse(message.data.toString())
          dispatch('app/socketEvent', data, { root: true })
        },
        { root: true }
      )
    }, 5000)
  },
  async axios ({ dispatch, rootState, commit }: ActionContext<State, any>, options: AxiosRequestConfig): Promise<any> {
    try {
      // @ts-ignore
      const instance = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL || '/',
        ...(rootState.auth.auth_token && {
          headers: {
            Authorization: `Token ${rootState.auth.auth_token}`
          }
        })
      })

      // @ts-ignore
      const data = await instance(options)

      return data
    } catch (error) {
      console.error(error)
      // @ts-ignore
      if (error?.response?.status === 403) {
        await dispatch('notification', {
          content: 'Sesion agotada, por favor introduzca sus credenciales nuevamente'
        })
        await dispatch('auth/logout', {}, { root: true })
      }

      commit('ADD_LOG', { title: 'REQUEST_ERROR', color: 'error', message: error })

      throw error
    }
  },
  async makeRequestWithNotifications (
    { dispatch }: ActionContext<State, any>,
    func: Function
  ) {
    const toastID: ToastID = await dispatch('notification', {
      content: 'Realizando Solicitud',
      timeout: false,
      info: 'info'
    })
    try {
      await func()
      await dispatch('notification', {
        content: 'Exito',
        type: 'success',
        timeout: 10000
      })
    } catch (error) {
      await dispatch('notifyToast', { msj: 'Ha ocurrido un error al realizar la solicitud', type: 'error' })
      throw error
    } finally {
      dispatch('notificationDismiss', toastID)
    }
  },
  async makeRequestWithNotificationsErrorMessage (
    { dispatch }: ActionContext<State, any>,
    func: Function
  ) {
    // const toastID: ToastID = await dispatch('notification', { content: 'Realizando Solicitud', timeout: false, info: 'info' })
    try {
      await func()
      await dispatch('notifyToast', { msj: 'Exito!', type: 'success' })
    } catch (error) {
      await dispatch('notifyToast', {
        // @ts-ignore
        msj: error.response.data.data.message,
        type: 'error'
      })
      // await dispatch('notification', { content: error.response.data.data.message, type: 'error', timeout: false })
      throw error
    } finally {
      dispatch('notificationDismiss')
    }
  },
  notification (
    _: ActionContext<State, any>,
    options: ToastOptions & { content: string }
  ): ToastID {
    // @ts-ignore
    const toast = this._vm.$toast
    // @ts-ignore
    return toast(options.content, options)
  },
  cleanNotifications () {
    // @ts-ignore
    this._vm.$toast.clear()
  },
  notificationToasted (
    _: ActionContext<State, any>,
    options: ToastOptions & { content: string }
  ) {
    // @ts-ignore
    const toast = this._vm.$toasted.show(options.content, {
      action: {
        icon: 'close',
        // @ts-ignore
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
        class: 'toasted-action-custom'
      },
      ...options,
      // @ts-ignore
      className: 'custom-toast ' + options.className
    })
    // @ts-ignore
    if (options.clear) {
      setTimeout(() => {
        toast.goAway(0)
      }, 4000)
    }
  },
  notifyToast (_: ActionContext<State, any>, options: { msj: string, type: string, icon?: string }) {
    const icon = options.icon || 'mdi-bell'
    Vue.notify({
      group: 'app',
      type: options.type,
      title: 'Notificación de operación',
      text: options.msj,
      data: {
        icon: icon
      }
    })
  },
  notificationDismiss (_: ActionContext<State, any>, id: ToastID): void {
    // @ts-ignore
    const toast = this._vm.$toast
    toast.dismiss(id)
  },
  async playSound (
    _: ActionContext<State, string>,
    audioPayload: {
      url?: string;
      type:
      | 'done'
      | 'alert'
      | 'notification'
      | 'camera_alert'
      | 'notify1'
      | 'notify2'
      | 'notify3'
      | 'zumbido'
      | 'message'
      | 'message-low'
      | 'error';
    }
  ): Promise<HTMLAudioElement | void> {
    try {
      if (audioPayload) {
        if (audioPayload.url) {
          const audio = new Audio(audioPayload.url)
          await audio.play()
          return audio
        } else if (audioPayload.type) {
          switch (audioPayload.type) {
            case 'alert':
              await new Audio('/audio/alert.mp3').play()
              break
            case 'done':
              await new Audio('/audio/done.mp3').play()
              break
            case 'notification':
              await new Audio('/audio/notification.mp3').play()
              break
            case 'camera_alert':
              await new Audio('/audio/notification.mp3').play()
              break
            case 'notify1':
              await new Audio('/audio/notify1.wav').play()
              break
            case 'notify2':
              await new Audio('/audio/notify2.wav').play()
              break
            case 'notify3':
              await new Audio('/audio/notify3.wav').play()
              break
            case 'zumbido':
              await new Audio('/audio/zumbido.wav').play()
              break
            case 'message':
              await new Audio('/audio/message.mp3').play()
              break
            case 'message-low':
              await new Audio('/audio/message-low.mp3').play()
              break
            case 'error':
              await new Audio('/audio/error.mp3').play()
              break
            default:
              await new Audio('/audio/camera_alert.wav').play()
              break
          }
        } else {
          await new Audio('/audio/notify.wav').play()
        }
      } else {
        await new Audio('/audio/notify.wav').play()
      }
    } catch (error) {
      await new Audio('/audio/notify.wav').play()
      console.error(error)
    }
  },
  async copyMessage (
    { dispatch, commit }: ActionContext<State, string>,
    msPayload: { message: string }
  ): Promise<void> {
    const toastID = await dispatch('notification', {
      content: 'Copiando el contenido',
      type: 'warning',
      timeout: -1
    })
    try {
      await navigator.clipboard.writeText(msPayload.message)
      dispatch('notificationDismiss', toastID)
      await dispatch('notification', {
        content: 'Copiado!!',
        type: 'info',
        timeout: 10000
      })
    } catch (error) {
      console.error(error)
      dispatch('notificationDismiss', toastID)
      commit('ADD_LOG', {
        title: 'NAVIGATOR_CLIPBOARD_ERROR',
        color: 'error',
        message: error
      })
      await dispatch('notification', {
        content: 'Error al copiar el contenido',
        type: 'error',
        timeout: 10000
      })
    }
  },
  addLogWithError (
    context: ActionContext<State, string>,
    {
      title,
      color,
      error,
      message
    }: { error: any; title: string; color: string; message: string }
  ) {
    context.commit('ADD_LOG', {
      title,
      color,
      message,
      payload: serializeError(error)
    })
  },
  getURLFromPartial ({ state }: ActionContext<State, string>, { url, useURLOrigin }: { url: string; useURLOrigin: boolean }): string {
    const origin = useURLOrigin ? getUrlOrigin() : state.BASE_URL
    return `${origin}${url}`
  },
  async getFileFromURL (_: ActionContext<State, string>, { url }: { url: string }): Promise<Blob> {
    const result = await axios.get(url, { responseType: 'blob' })
    return result.data
  },
  async getCeleryTaskResult ({ dispatch }: ActionContext<State, string>, { id, axiosConfig = {} as AxiosRequestConfig, responseType = 'json' }: { id: string; responseType?: 'json' | 'raw', axiosConfig?: AxiosRequestConfig }): Promise<ICeleryTaskResult> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `apis/tasks/result/${id}?response_type=${responseType}`,
          method: 'GET',
          ...axiosConfig
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_CELERY_TASK_RESULT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getCeleryTaskStatus ({ dispatch }: ActionContext<State, string>, id: string): Promise<ICeleryTaskResult['status']> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `apis/tasks/status/${id}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_CELERY_TASK_STATUS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  /**
   * @description
   * Check celery task status in interval time until task ends or reach maximum tries.
   * If task ends with 'SUCCESS' status will return task result if returnResult is true,
   * If task ends with 'FAILURE' status will return task result if returnResult is true,
   * If task reach maximum tries will return 'TIMEOUT'
   * @param {string} id - Celery task id
   * @param {number} tries - Maximum tries to check task status (default 36)
   * @param {number} intervalTime - Time to wait between each check (default 5000)
   * @param {boolean} raw - If true will return raw response from server (default false)
   * @param {AxiosRequestConfig} sucessRequestConfig - Axios request config for success request (default {})
   * @param {AxiosRequestConfig} errorRequestConfig - Axios request config for error request (default {})
   * @param {'json'|'raw'|'view_error'} sucessResponseType - Response type for success request (default 'json')
   * @param {'json'|'raw'|'view_error'} errorResponseType - Response type for error request (default 'json')
   * @param {boolean} returnResult - If true will return task result (default false)
   * @returns {Promise<ICeleryTaskResult['status'] | any>}
   */
  checkCeleryTaskStatus ({ dispatch }: ActionContext<State, string>,
    {
      id,
      tries = 36,
      intervalTime = 5000,
      raw = false,
      sucessRequestConfig = {} as AxiosRequestConfig,
      errorRequestConfig = {} as AxiosRequestConfig,
      sucessResponseType = 'json',
      errorResponseType = 'json',
      returnResult
    }: {
      id: string;
      tries: number;
      intervalTime: number;
      returnResult?: boolean;
      raw?: boolean,
      sucessRequestConfig?: AxiosRequestConfig;
      errorRequestConfig?: AxiosRequestConfig;
      sucessResponseType? : 'json' | 'raw' | 'view_error';
      errorResponseType? : 'json' | 'raw' | 'view_error';
    }
  ): Promise<ICeleryTaskResult['status'] | any> {
    return new Promise<ICeleryTaskResult['status']>((resolve, reject) => {
      let triesCount = 0
      const interval = setInterval(async () => {
        const status: ICeleryTaskResult['status'] = await dispatch('getCeleryTaskStatus', id)
        switch (status) {
          case 'PENDING':
          case 'STARTED':
            break
          case 'RETRY':
          case 'FAILURE':
            clearInterval(interval)
            if (returnResult) {
              try {
                const result = await dispatch('getCeleryTaskResult', {
                  id, raw, axiosConfig: errorRequestConfig, responseType: errorResponseType
                })
                reject(result)
              } catch (error) {
                reject(error)
              }
            } else {
              reject(status)
            }
            break
          case 'SUCCESS':
            clearInterval(interval)
            if (returnResult) {
              try {
                const result = await dispatch('getCeleryTaskResult', {
                  id, raw, axiosConfig: sucessRequestConfig, responseType: sucessResponseType
                })
                resolve(result)
              } catch (error) {
                reject(error)
              }
            } else {
              resolve(status)
            }
            break
        }
      }, intervalTime)
      triesCount++
      if (triesCount === tries) {
        clearInterval(interval)
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('TIMEOUT')
      }
    })
  },
  async getExcelTemplateData ({ dispatch }: ActionContext<State, string>, { id_unitconsole, startDateAt, endDateAt }: { id_unitconsole: string; startDateAt: string; endDateAt: string }): Promise<IExcelTemplateData> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/unit/template/data/${id_unitconsole}?start_maintenance_date=${startDateAt}&end_maintenance_date=${endDateAt}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_EXCEL_TEMPLATE_DATA', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  /**
   * @description Genera un template de Excel, reemplazando los valores dinamicos en el template con los datos del objeto data.
   * @param workbook El objeto workbook de ExcelJS.
   * @param sheet La hoja de Excel en la que se va a generar el template.
   * @param data El objeto con los datos que se van a reemplazar en el template.
   * @returns Promise<void>
   */
  async generateExcelTemplate (_: ActionContext<State, string>, { workbook, sheet, data }: { data: any; workbook: ExcelJS.Workbook; sheet: ExcelJS.Worksheet; }) {
    await fillSheetTemplate(sheet, async (cell: ExcelJS.Cell, template: IGetTemplateVariables, usedRegex: RegExp, currValue: string, setCellValue: (value: string) => void) => {
      switch (template.prefix) {
        case '@': { // Para las imagenes
          setCellValue('')

          // Leemos el topLeftPosition y bottomRightPosition para insertarlas después
          const topLeftPosition = template.args.at(0)
          const bottomRightPosition = template.args.at(1)
          if (!topLeftPosition || !bottomRightPosition) return

          // Creamos una promesa para obtener la imagen
          const url = template.content
          const response = await axios.get(url, { responseType: 'arraybuffer', timeout: 10000 })
          const imageId = workbook.addImage({
            // @ts-ignore
            buffer: response.data,
            extension: 'jpeg' // Ajustar según corresponda
          })
          // Guardamos la info de la posición
          sheet.addImage(imageId, `${topLeftPosition}:${bottomRightPosition}`)
          break
        }
        case '#': { // Cuando se encuentra una imagen en el data
          setCellValue('')
          if (!data[template.content]) return

          // Leemos el topLeftPosition y bottomRightPosition para insertarlas después
          const topLeftPosition = template.args.at(0)
          const bottomRightPosition = template.args.at(1)
          if (!topLeftPosition || !bottomRightPosition) return

          // Creamos una promesa para obtener la imagen
          const buffer = data[template.content]
          const imageId = workbook.addImage({
            // @ts-ignore
            buffer,
            extension: 'jpeg' // Ajustar según corresponda
          })
          // Guardamos la info de la posición
          sheet.addImage(imageId, `${topLeftPosition}:${bottomRightPosition}`)
          break
        }
        case '$': { // Para las tablas
          setCellValue('')

          const tableData: ITemplateTableShape | undefined = data[template.content]
          if (!tableData) return

          const currCellPosition = cell.address
          const columns = tableData.rowsHeaders ? [{ name: tableData.title }, ...tableData.columnsHeaders] : tableData.columnsHeaders
          const rows = tableData.rowsHeaders ? tableData.rowsHeaders.map((rowHeader, index) => [rowHeader.name, ...tableData.data[index]]) : tableData.data

          if (tableData.beforeCreateTable) {
            await tableData.beforeCreateTable({
              template, tableData, cell, columns, rows
            })
          }

          const table = sheet.addTable({
            name: tableData.title,
            ref: currCellPosition,
            headerRow: true,
            columns,
            rows,
            ...tableData.props
          })

          // @ts-ignore
          const bounds = getExcelJSTableBounds(table.model.tableRef)
          const columnInitPosition = getColumnPosition(bounds.topLeft.letter)
          const columnEndPosition = getColumnPosition(bounds.endRight.letter)

          const header = sheet.getRow(bounds.topLeft.digit) // La fila del encabezado está en `row`

          // @ts-ignore
          if (tableData.beforeTableEnd) await tableData.beforeTableEnd(table.model.tableRef, header, rows, bounds, columnInitPosition, columnEndPosition)

          break
        }
        default: { // Para el resto
          setCellValue(currValue.replace(usedRegex, data[template.content] || ''))
        }
      }
    })
  }
}
