import { IAccountSocialMedia } from '@/store/interfaces/SocialAccounts.interface'
import BaseSocialMediaStrategy, { IFileValidationError } from './BaseSocialMediaStrategy'
import { SOCIAL_MEDIA_TYPE } from '@/store/catalogs/SOCIAL_MEDIA_TYPE'
import FactorySocialStrategy from './FactorySocialStrategy'

export default class StrategyAccountSocialMediaManager {
  // ===========================================================
  // Public properties
  // ===========================================================
  /** Set of allowed mime types of all strategies */
  public getSetAllowedMimeTypes: Set<string>
  public currentFilesErrors: IFileValidationError[]
  public currentStrategiesCount: number
  public mostShowAssetsTextArea: boolean

  // ===========================================================
  // Private properties
  // ===========================================================
  /** List of accounts */
  private accounts: IAccountSocialMedia[]
  /** Record of strategies */
  private strategies: Record<number, BaseSocialMediaStrategy>

  constructor () {
    this.accounts = []
    this.strategies = {}
    this.currentFilesErrors = []
    this.currentStrategiesCount = 0
    this.getSetAllowedMimeTypes = new Set()
    this.mostShowAssetsTextArea = false
  }

  public setAccounts (accounts: IAccountSocialMedia[]): void {
    this.accounts = accounts
    this.updateStrategies()
  }

  public getStrategies (): Record<SOCIAL_MEDIA_TYPE, BaseSocialMediaStrategy> {
    return this.strategies
  }

  public areAllStrategiesHaveValidFiles (files: File[]): boolean {
    return Object.values(this.strategies).every(strategy => strategy.hasFilesValidMimeTypes(files))
  }

  public get allowedMimeTypes (): string[] {
    return Array.from(this.getSetAllowedMimeTypes)
  }

  public get haveInvalidFiles (): boolean {
    return this.currentFilesErrors.length > 0
  }

  public async validateFiles (files: File[]): Promise<IFileValidationError[]> {
    const errors = []
    for (const strategy of Object.values(this.strategies)) {
      errors.push(...await strategy.validateFiles(files))
    }

    this.currentFilesErrors = errors

    return this.currentFilesErrors
  }

  public showAssetsTextArea (files: File[]): boolean {
    const mostShow = Object.values(this.strategies).some(strategy => strategy.showAssetsTextArea(files))
    this.mostShowAssetsTextArea = mostShow
    return mostShow
  }

  // ===========================================================
  // Private methods
  // ===========================================================
  private updateStrategies (): void {
    let allowedMimeTypes = new Set<string>()
    const strategies: Record<number, BaseSocialMediaStrategy> = {}

    for (const account of this.accounts) {
      if (strategies[account.id_asm_type]) continue

      const strategy = FactorySocialStrategy.getSocialStrategy(account.id_asm_type)
      // @ts-ignore
      allowedMimeTypes = allowedMimeTypes.union(strategy.allowedMimeTypes)
      strategies[account.id_asm_type] = strategy
    }

    this.strategies = strategies
    this.currentStrategiesCount = Object.keys(strategies).length
    this.getSetAllowedMimeTypes = allowedMimeTypes
  }
}
